<template>
  <Breadcrumbs main="Email" mains="Emails" title="Emails" />
  <div class="container-fluid">
    <div class="email-wrap email-main-wrapper">
      <div class="row">
        <!-- <EmailsSidebar /> -->
        <div class="col-xxl-12 col-xl-12 box-col-12">
          <div class="email-right-aside">
            <EmailsHeader />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from "vue";
const EmailsSidebar = defineAsyncComponent(
  () => import("@/components/theme/emails/EmailsSidebar.vue")
);
const EmailsHeader = defineAsyncComponent(
  () => import("@/components/theme/emails/EmailsHeader.vue")
);
</script>