import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, isRef as _isRef, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox } from "vue"
import _imports_0 from '@/assets/svg/icon-sprite.svg'
import _imports_1 from '@/assets/images/user/12.png'
import _imports_2 from '@/assets/images/email-template/pdfs.png'


const _hoisted_1 = _imports_0 + '#back-arrow'
const _hoisted_2 = _imports_0 + '#print'
const _hoisted_3 = _imports_0 + '#menubar'
const _hoisted_4 = _imports_0 + '#attchment'
const _hoisted_5 = { class: "container-fluid" }
const _hoisted_6 = { class: "email-wrap email-main-wrapper" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-xxl-9 col-xl-9 box-col-9" }
const _hoisted_9 = { class: "email-right-aside" }
const _hoisted_10 = { class: "card email-body email-read show" }
const _hoisted_11 = { class: "mail-header-wrapper header-wrapper1" }
const _hoisted_12 = { class: "mail-header1" }
const _hoisted_13 = { class: "light-square" }
const _hoisted_14 = { class: "mail-body1" }
const _hoisted_15 = ["xlink:href"]
const _hoisted_16 = ["xlink:href"]
const _hoisted_17 = { class: "mail-body-wrapper email-reply-body-wrapper" }
const _hoisted_18 = { class: "user-mail-wrapper" }
const _hoisted_19 = { class: "user-title" }
const _hoisted_20 = { class: "dropdown-subtitle" }
const _hoisted_21 = { class: "onhover-dropdown" }
const _hoisted_22 = { class: "btn p-0 dropdown-button" }
const _hoisted_23 = { class: "inbox-security onhover-show-div" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { key: 2 }
const _hoisted_27 = { key: 3 }
const _hoisted_28 = { class: "inbox-options" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { class: "user-body" }
const _hoisted_31 = { class: "mail-subcontent" }
const _hoisted_32 = ["srcdoc"]
const _hoisted_33 = { class: "user-footer" }
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { claas: "gap-2" }
const _hoisted_36 = { class: "attchment-file common-flex" }
const _hoisted_37 = { class: "common-flex align-items-center" }
const _hoisted_38 = { class: "d-block" }
const _hoisted_39 = ["onClick"]
const _hoisted_40 = { class: "d-flex user-footer justify-content-end gap-2" }
const _hoisted_41 = {
  key: 0,
  class: "user-footer"
}
const _hoisted_42 = { class: "toolbar-box" }
const _hoisted_43 = { class: "row mb-1" }
const _hoisted_44 = { class: "col-sm-12 px-0 py-0" }
const _hoisted_45 = { class: "card-wrapper py-0 d-flex gap-2" }
const _hoisted_46 = { class: "flex email_fields" }
const _hoisted_47 = { class: "row mb-1" }
const _hoisted_48 = { class: "col-sm-12 px-0 py-0" }
const _hoisted_49 = { class: "card-wrapper py-0 d-flex gap-2" }
const _hoisted_50 = {
  key: 0,
  class: "option__desc"
}
const _hoisted_51 = {
  key: 0,
  class: "option__title"
}
const _hoisted_52 = {
  key: 1,
  class: "option__title"
}
const _hoisted_53 = {
  key: 2,
  class: "option__title"
}
const _hoisted_54 = { class: "row mb-1" }
const _hoisted_55 = { class: "col-sm-12 px-0 py-0" }
const _hoisted_56 = { class: "card-wrapper py-0 d-flex gap-2" }
const _hoisted_57 = {
  key: 0,
  class: "option__desc"
}
const _hoisted_58 = {
  key: 0,
  class: "option__title"
}
const _hoisted_59 = {
  key: 1,
  class: "option__title"
}
const _hoisted_60 = {
  key: 2,
  class: "option__title"
}
const _hoisted_61 = { class: "row mb-1" }
const _hoisted_62 = { class: "col-sm-12 px-0 py-0" }
const _hoisted_63 = { class: "card-wrapper py-0 d-flex gap-2" }
const _hoisted_64 = {
  key: 0,
  class: "option__desc"
}
const _hoisted_65 = {
  key: 0,
  class: "option__title"
}
const _hoisted_66 = {
  key: 1,
  class: "option__title"
}
const _hoisted_67 = {
  key: 2,
  class: "option__title"
}
const _hoisted_68 = { class: "row mb-1" }
const _hoisted_69 = { class: "col-sm-12 px-0 py-0" }
const _hoisted_70 = { class: "card-wrapper py-0 d-flex gap-2" }
const _hoisted_71 = { class: "d-flex gap-2 justify-content-end my-2" }
const _hoisted_72 = { class: "common-flex switch-wrapper icon-switch" }
const _hoisted_73 = { class: "d-flex" }
const _hoisted_74 = { class: "flex-grow-1 text-end" }
const _hoisted_75 = { class: "switch" }
const _hoisted_76 = {
  key: 0,
  class: "common-flex switch-wrapper icon-switch"
}
const _hoisted_77 = { class: "d-flex" }
const _hoisted_78 = { class: "flex-grow-1 text-end" }
const _hoisted_79 = { class: "switch" }
const _hoisted_80 = { class: "d-flex justify-content-between align-items-start" }
const _hoisted_81 = { class: "mt-2 pt-2" }
const _hoisted_82 = { class: "d-flex flex-column attachment-wrapper gap-2" }
const _hoisted_83 = { class: "d-flex gap-1" }
const _hoisted_84 = { class: "filename" }
const _hoisted_85 = ["onClick"]
const _hoisted_86 = { class: "d-flex gap-1" }
const _hoisted_87 = { class: "filename" }
const _hoisted_88 = ["onClick"]
const _hoisted_89 = {
  class: "col-xxl-3 col-xl-3 box-col-3 p-0",
  style: {"position":"fixed","right":"0px","width":"22%"}
}
const _hoisted_90 = { class: "email-right-aside p-2" }
const _hoisted_91 = { class: "p-2 search-wrapper mb-2" }
const _hoisted_92 = { class: "d-flex align-items-center gap-2" }
const _hoisted_93 = {
  key: 0,
  class: "card email-body email-read show gap-2 searched-wrapper"
}
const _hoisted_94 = { class: "d-flex flex-column" }
const _hoisted_95 = { class: "d-flex gap-2" }
const _hoisted_96 = { class: "assigned_name" }
const _hoisted_97 = { class: "d-flex gap-2" }
const _hoisted_98 = { class: "assigned_event" }
const _hoisted_99 = { class: "assigned_code d-flex flex-column gap-1" }
const _hoisted_100 = ["onClick"]
const _hoisted_101 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 512 512",
  style: {"width":"15px","height":"15px"},
  class: "cursor-pointer"
}
const _hoisted_102 = { class: "mt-0 d-flex justify-content-end" }
const _hoisted_103 = ["onClick"]
const _hoisted_104 = { class: "card email-body email-read show gap-2 mb-0" }
const _hoisted_105 = { class: "d-flex flex-column" }
const _hoisted_106 = { class: "d-flex gap-2" }
const _hoisted_107 = { class: "assigned_name" }
const _hoisted_108 = { class: "d-flex gap-2" }
const _hoisted_109 = { class: "assigned_event" }
const _hoisted_110 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_111 = ["onClick"]
const _hoisted_112 = { class: "assigned_code d-flex gap-1" }
const _hoisted_113 = ["onClick"]
const _hoisted_114 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 512 512",
  style: {"width":"15px","height":"15px"},
  class: "cursor-pointer"
}
const _hoisted_115 = { class: "p-2 search-wrapper" }
const _hoisted_116 = { class: "d-flex align-items-center gap-2" }
const _hoisted_117 = {
  key: 1,
  class: "card email-body email-read show gap-2 article-wrapper",
  style: {"height":"400px","overflow":"scroll"}
}
const _hoisted_118 = { class: "d-flex flex-column" }
const _hoisted_119 = { class: "d-flex gap-2 justify-between-content article-headline" }
const _hoisted_120 = { class: "assigned_name flex-1" }
const _hoisted_121 = ["onClick"]
const _hoisted_122 = { class: "article-content d-flex flex-column gap-1" }
const _hoisted_123 = ["innerHTML"]

import Swal from "sweetalert2";
import debounce from "lodash.debounce";
import VueMultiselect from "vue-multiselect";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import {
  reactive,
  defineAsyncComponent,
  watch,
  ref,
  onMounted,
  nextTick,
  useTemplateRef,
  onUnmounted,
} from "vue";
import { mail } from "@/core/data/emails";
import { toast } from "vue3-toastify";
import {
  AssignEmailBySearched,
  GetEmailSync,
  GetInitDesign,
  GetSearchByKeyword,
  GetSearchSystemByKeyword,
  ReturnDesignJsonByAddTemplate,
  SendEmailUsingNylas,
  SetEmailCategory,
} from "@/services/ApiCore";
import TopolPlugin from "@topol.io/editor";
import { useCommonStore } from "@/store/common";
import { useRoute, useRouter } from "vue-router";
import { GetArticles } from "@/services/ApiArticles";

export default /*@__PURE__*/_defineComponent({
  __name: 'EmailsDetail',
  emits: ["closed", "sent", "trashed"],
  setup(__props, { emit: __emit }) {

let to_subject = ref<string>("");
let email_subject = ref<string>("");
let email_to_name = ref<string>("");
let email_to_email = ref<string>("");
let email_from_name = ref<string>("");
let email_from_email = ref<string>("");
let email_body = ref<string>("");
let email_reply_email = ref<string>("");
let email_reply_name = ref<string>("");
let email_easyreg = ref<any>([]);
let searched_email_easyreg = ref<any>([]);
let searched_articles = ref<any>([]);

let email_cc_emails = ref<any>([]);
let email_bcc_emails = ref<any>([]);
let send_attachments = ref<any>([]);
let send_attachments_attached = ref<any>([]);

let send_from_email = ref<any>("martin@easyreg.ca");
let to_searched_result = ref<any>([]);
let cc_searched_result = ref<any>([]);
let bcc_searched_result = ref<any>([]);
let send_to_emails = ref<any>([]);
let send_cc_emails = ref<any>([]);
let send_bcc_emails = ref<any>([]);
let email_date = ref<string>("");
let currentJson = ref<string>("");
let currentHtml = ref<string>("");
let reply_section = ref<boolean>(false);
let forwarded_email = ref<boolean>(false);
let keyword = ref<string>("");
let keyword_for_article = ref<string>("");
let email_attachments = ref<any>([]);
let email_closed = ref<boolean>(true);
let email_receipt = ref<boolean>(false);
let isLoading = ref<boolean>(false);
let isToLoading = ref<boolean>(false);
let isCCLoading = ref<boolean>(false);
let isBCCLoading = ref<boolean>(false);
let topolPluginInit = ref<boolean>(false);
let emit = __emit;
let sendingEmail = ref<boolean>(false);
let router = useRouter();
const route = useRoute();
const commonStore = useCommonStore();
function trash() {
  (async () => {
    try {
      await SetEmailCategory(route.params.emails_id.toString(), "TRASH");
      router.push(`/app/emails/${route.params.tab}`);
    } catch (e) {
      console.log("trash error", e);
    }
  })();
}
function back() {
  router.push(`/app/emails/${route.params.tab}`);
}
const emailBodyRef = useTemplateRef("emailBodyRef");
const uploadRef = useTemplateRef("uploadRef");
function openEmail(tab: string, emails_id: string) {
  (async () => {
    try {
      isLoading.value = true;
      const {
        subject,
        to_name,
        to_email,
        from_name,
        from_email,
        reply_name,
        reply_email,
        cc_emails,
        bcc_emails,
        body,
        date,
        easyreg,
        attachments,
      } = await GetEmailSync(tab, emails_id);
      email_easyreg.value = easyreg;
      email_subject.value = subject;
      email_to_name.value = to_name;
      email_to_email.value = to_email;
      email_from_name.value = from_name;
      email_from_email.value = from_email;
      email_reply_email.value = reply_email;
      email_reply_name.value = reply_name;
      email_cc_emails.value = cc_emails;
      email_bcc_emails.value = bcc_emails;
      email_date.value = date;

      email_attachments.value = attachments;
      to_subject.value = `Re: ${subject}`;

      email_body.value = body;
      isLoading.value = false;

      send_to_emails.value = [];
      if (email_reply_email.value != "") {
        send_to_emails.value = [
          ...send_to_emails.value,
          {
            firstname: email_reply_name.value,
            lastname: "",
            email: email_reply_email.value,
          },
        ];
      } else {
        send_to_emails.value = [
          ...send_to_emails.value,
          {
            firstname: email_from_name.value,
            lastname: "",
            email: email_from_email.value,
          },
        ];
      }

      send_cc_emails.value = email_cc_emails.value.map((x: any) => {
        return {
          firstname: x.cc_email,
          lastname: "",
          email: x.cc_email,
        };
      });
      send_bcc_emails.value = email_bcc_emails.value.map((x: any) => {
        return {
          firstname: x.bcc_email,
          lastname: "",
          email: x.bcc_email,
        };
      });
    } catch (e) {
      console.log("openEmail error", e);
    }
  })();
}
function downloadAttachment(
  attachments_filename: string,
  attachments_size: any,
  attachments_content_type: string,
  attachments_id: string
) {
  try {
    let file = `https://api.us.nylas.com/v3/grants/${process.env.NYLAS_GRANT_ID}/attachments/${attachments_id}/download?message_id=${route.params.emails_id}`;
    let headers = new Headers();
    headers.append("Authorization", `Bearer ${process.env.NYLAS_API_KEY}`);

    fetch(file, { headers })
      .then((response) => response.blob())
      .then((blobby) => {
        var reader = new FileReader();
        // this function is triggered once a call to readAsDataURL returns
        reader.onload = function (event: any) {
          let file = event.target.result;
          send_attachments_attached.value = [
            ...send_attachments_attached.value,
            {
              handleId: generateId(20),
              file,
              name: attachments_filename,
              size: attachments_size,
              type: attachments_content_type,
              attachments_id: attachments_id,
              uploaded: "A",
            },
          ];
        };
        // trigger the read from the reader...
        reader.readAsDataURL(blobby);
      });
  } catch (e) {
    console.log("handleDownload error", e);
  }
}
function handleDownload(attachments_name: string, attachments_id: string) {
  (async () => {
    try {
      isLoading.value = true;
      console.log("attachments_id", attachments_id);

      let anchor = document.createElement("a");
      document.body.appendChild(anchor);

      let file = `https://api.us.nylas.com/v3/grants/${process.env.NYLAS_GRANT_ID}/attachments/${attachments_id}/download?message_id=${route.params.emails_id}`;
      let headers = new Headers();
      headers.append("Authorization", `Bearer ${process.env.NYLAS_API_KEY}`);

      fetch(file, { headers })
        .then((response) => response.blob())
        .then((blobby) => {
          let objectUrl = window.URL.createObjectURL(blobby);

          anchor.href = objectUrl;
          anchor.download = `${attachments_name}`;
          anchor.click();
          isLoading.value = false;
          window.URL.revokeObjectURL(objectUrl);
        });

      isLoading.value = false;
    } catch (e) {
      console.log("handleDownload error", e);
    }
  })();
}
function handleSend() {
  (async () => {
    sendingEmail.value = true;
    TopolPlugin.save();
  })();
}
function loadEditor() {
  if (topolPluginInit.value) {
    TopolPlugin.destroy();
  }
  const TOPOL_OPTIONS = {
    id: "#editor-container",
    enableAutosaves: true,
    autosaveInterval: 30,
    authorize: {
      apiKey: process.env.TOPOL_API_KEY,
      userId: 1,
    },
    disableAlerts: true,
    light: true,
    premadeBlocks: [
      {
        name: "Online Attendee Site",
        blocks: [
          {
            img:
              "https://2023-easyreg-prod.s3.ca-central-1.amazonaws.com/attachments/online_attendee_site_premade_block.png", // Image url, for best experience use width > 330 px
            name: "Online Attendee Site Block", // Or name if not image available
            // MJML JSON
            definition: [
              {
                tagName: "mj-section",
                attributes: {
                  "full-width": "600px",
                  padding: "10px 0px 10px 0px",
                  "mj-class": "section",
                },
                type: null,
                children: [
                  {
                    tagName: "mj-column",
                    attributes: {
                      width: "100%",
                      "vertical-align": "top",
                    },
                    children: [
                      {
                        tagName: "mj-text",
                        content:
                          '<p>You can access the Online Attendee Site using the following link:<br>###attendee.accesslink###</p>\n          <p style="font-weight: bold; margin-bottom: 10px; margin-top: 10px;">OR</p>\n          <ul>\n              <li>Go to https://online.ourconference.events</li>\n              <li>Enter ###attendee.email### under Email Address</li>\n              <li>Enter ###attendee.code### under Code</li>\n              <li>Click Login</li>\n          </ul>',
                        attributes: {
                          padding: "10px 10px",
                          align: "left",
                          "line-height": 1.3,
                          containerWidth: 600,
                        },
                        uid: "H1lqIiX4lm",
                      },
                    ],
                    uid: "SJ3I0XVx7",
                  },
                ],
                layout: 1,
                backgroundColor: "",
                backgroundImage: "",
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                uid: "rkqIjQNe7",
              },
            ],
          },
        ],
      },
    ],
    customBlocks: [],
    callbacks: {
      onInit(json: any, html: any) {
        topolPluginInit.value = true;
      },
      async onSave(json: any, html: any) {
        currentJson.value = json;
        currentHtml.value = html;
        if (sendingEmail.value) {
          try {
            if (send_to_emails.value.length == 0) {
              sendingEmail.value = false;
              return;
            }

            if (send_from_email.value == "") {
              sendingEmail.value = false;
              return;
            }
            if (to_subject.value == "") {
              sendingEmail.value = false;
              return;
            }
            console.log("to_subject", to_subject.value);
            console.log("email_closed", email_closed.value);
            isLoading.value = true;

            let forward_email = forwarded_email.value ? "Y" : "N";
            const { result } = await SendEmailUsingNylas(
              route.params.emails_id.toString(),
              send_from_email.value,
              JSON.stringify(send_to_emails.value),
              JSON.stringify(send_cc_emails.value),
              JSON.stringify(send_bcc_emails.value),
              to_subject.value,
              currentHtml.value,
              send_attachments.value,
              send_attachments_attached.value,
              forward_email,
              email_closed.value ? "Y" : "N",
              email_receipt.value ? "Y" : "N"
            );
            isLoading.value = false;
            commonStore.toggleSent(result);
            sendingEmail.value = false;
            if (result == "success") {
              router.push("/app/emails/sent");
            } else {
            }
          } catch (e) {
            sendingEmail.value = false;
            console.log("handleSend error", e);
          }
        }
      },
      onLoaded(json: any, html: any) {
        TopolPlugin.save();
        // hide the loader
      },
    },
  };
  TopolPlugin.init(TOPOL_OPTIONS);
}
function loadDesign(json: any) {
  if (json) {
    TopolPlugin.load(json);
  }
}
function handleForward() {
  (async () => {
    reply_section.value = false;
    forwarded_email.value = true;
    send_to_emails.value = [];
    send_cc_emails.value = [];
    send_bcc_emails.value = [];

    let sendPromise = [];
    for (let idx = 0; idx < email_attachments.value.length; idx++) {
      sendPromise.push(
        downloadAttachment(
          email_attachments.value[idx].attachments_filename,
          email_attachments.value[idx].attachments_size,
          email_attachments.value[idx].attachments_content_type,
          email_attachments.value[idx].attachments_id
        )
      );
    }
    try {
      await Promise.allSettled(sendPromise).catch(function (err) {
        console.log("sendPromise A error", err); // some coding error in handling happened
      });
    } catch (error) {
      console.log("sendPromise B error", error);
    }
    nextTick(async () => {
      await initEditor();
    });
  })();
}
function handleReply() {
  (async () => {
    reply_section.value = true;
    send_attachments_attached.value = [];
    forwarded_email.value = false;
    send_to_emails.value = [];
    if (email_reply_email.value != "") {
      send_to_emails.value = [
        ...send_to_emails.value,
        {
          firstname: email_reply_name.value,
          lastname: "",
          email: email_reply_email.value,
        },
      ];
    } else {
      send_to_emails.value = [
        ...send_to_emails.value,
        {
          firstname: email_from_name.value,
          lastname: "",
          email: email_from_email.value,
        },
      ];
    }

    send_cc_emails.value = email_cc_emails.value.map((x: any) => {
      return {
        firstname: x.cc_email,
        lastname: "",
        email: x.cc_email,
      };
    });
    send_bcc_emails.value = email_bcc_emails.value.map((x: any) => {
      return {
        firstname: x.bcc_email,
        lastname: "",
        email: x.bcc_email,
      };
    });

    nextTick(async () => {
      await initEditor();
    });
  })();
}
function handleReplyAll() {
  (async () => {
    reply_section.value = true;
    nextTick(async () => {
      await initEditor();
    });
  })();
}
function initEditor() {
  (async () => {
    try {
      loadEditor();
      const { json, html } = await GetInitDesign(
        route.params.emails_id.toString(),
        forwarded_email.value ? "Y" : "N"
      );
      currentJson.value = json;
      currentHtml.value = html;
      loadDesign(json);
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}
function generateId(len: any) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr).join("");
}
async function loadFile(files: any) {
  const filePromises = Array.from(files).map((file: any) => {
    // Return a promise per file
    return new Promise((resolve, reject) => {
      let response = {};
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          response = {
            handleId: generateId(20),
            pathname: file.name,
            name: file.name,
            size: file.size,
            type: file.type,
            image: null,
            file: file,
            attachments_id: "",
            uploaded: "B",
          };
          // Resolve the promise with the response value
          resolve(response);
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  });
  const fileInfos = await Promise.all(filePromises);
  console.log("fileInfos", fileInfos);
  return fileInfos;
}
async function uploadFile(e: any) {
  if (e.target.files.length === 0) return;
  console.log("e.target.files", e.target.files);
  const result = await loadFile(e.target.files);
  console.log("result", result);
  send_attachments.value = [...send_attachments.value, ...result];
}
function handleRemoveAttachment(file: any) {
  send_attachments.value = send_attachments.value.filter(
    (x: any) => x.handleId != file.handleId
  );
}
function handleAttachFiles() {
  if (uploadRef.value) {
    uploadRef.value.click();
  }
}
function handleCopyCode(value: string) {
  const el = document.createElement("textarea");
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);

  toast.success("Copied! ", {
    position: "top-right",
    autoClose: 2000,
  });
}
async function searchTerm(e: any) {
  if (e.which == 13) {
    if (keyword.value != "") {
      searched_email_easyreg.value = await GetSearchSystemByKeyword(keyword.value);
    }
    e.preventDefault();
  }
}
async function searchArticles(e: any) {
  if (e.which == 13) {
    if (keyword_for_article.value != "") {
      const { rows } = await GetArticles("keyword", keyword_for_article.value, {});
      searched_articles.value = rows;
    }
    e.preventDefault();
  }
}
async function handleReceipt(code: string, client: string, event: string) {
  let anchor = document.createElement("a");
  document.body.appendChild(anchor);
  let file = `https://api.easyreg2023.ca/api/downloadCRMReceiptByCode/${code}/${client}/${event}`;

  let headers = new Headers();
  fetch(file, { headers })
    .then((response) => response.blob())
    .then((blobby) => {
      let objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = `Receipt-${code}.pdf`;
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
      toast.success("Done! ", {
        position: "top-right",
        autoClose: 2000,
      });
    });
}
async function handleAssign(item: any) {
  await AssignEmailBySearched(route.params.emails_id.toString(), JSON.stringify(item));
  searched_email_easyreg.value = [];
  keyword.value = "";
  await openEmail(route.params.tab.toString(), route.params.emails_id.toString());
}
function getStatus(status: string) {
  if (status == "A") {
    return "Active";
  } else if (status == "D") {
    return "Deleted";
  } else if (status == "C") {
    return "Cancelled";
  } else {
    return "In Progress";
  }
}
function getStatusColor(status: string) {
  if (status == "A") {
    return "badge-primary";
  } else if (status == "D") {
    return "badge-danger";
  } else if (status == "C") {
    return "badge-danger";
  } else {
    return "badge-warning";
  }
}
function handleInsert(article_id: any) {
  (async () => {
    try {
      const { json } = await ReturnDesignJsonByAddTemplate(
        JSON.stringify(currentJson.value),
        article_id
      );
      loadDesign(json);
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}
function addNewToEmail(email: any, type: string) {
  console.log("string", type);
  const new_tag = {
    firstname: email,
    lastname: "",
    email: email,
  };
  if (type == "to") {
    send_to_emails.value.push(new_tag);
  } else if (type == "cc") {
    send_cc_emails.value.push(new_tag);
  } else if (type == "bcc") {
    send_bcc_emails.value.push(new_tag);
  }
}
const searchBCC = debounce(async (keyword: any) => {
  if (keyword.length < 5) {
    return;
  }
  isBCCLoading.value = false;
  bcc_searched_result.value = await GetSearchByKeyword(keyword);
  isBCCLoading.value = false;
}, 500);
const searchCC = debounce(async (keyword: any) => {
  if (keyword.length < 5) {
    return;
  }
  isCCLoading.value = true;
  cc_searched_result.value = await GetSearchByKeyword(keyword);
  isCCLoading.value = false;
}, 500);
const search = debounce(async (keyword: any) => {
  if (keyword.length < 5) {
    return;
  }
  isToLoading.value = true;
  to_searched_result.value = await GetSearchByKeyword(keyword);
  isToLoading.value = false;
}, 500);
onUnmounted(() => {
  if (topolPluginInit.value) {
    TopolPlugin.destroy();
  }
});
onMounted(() => {
  (async () => {
    reply_section.value = false;
    forwarded_email.value = false;
    sendingEmail.value = false;
    keyword.value = "";
    keyword_for_article.value = "";
    searched_articles.value = [];
    send_from_email.value = "martin@easyreg.ca";
    searched_email_easyreg.value = [];
    email_easyreg.value = [];
    send_attachments.value = [];
    send_attachments_attached.value = [];
    send_to_emails.value = [];
    send_cc_emails.value = [];
    send_bcc_emails.value = [];
    await openEmail(route.params.tab.toString(), route.params.emails_id.toString());
  })();
});

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_vue_feather = _resolveComponent("vue-feather")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Email",
      mains: "Emails",
      title: "Emails"
    }),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_unref(Loading), {
            active: _unref(isLoading),
            "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => (_isRef(isLoading) ? (isLoading).value = $event : isLoading = $event)),
            "can-cancel": true,
            "is-full-page": false,
            height: 40,
            width: 40,
            loader: `dots`,
            color: `#2B5582`,
            "background-color": `#FFFFFF`
          }, null, 8, ["active"]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      (_openBlock(), _createElementBlock("svg", {
                        class: "btn-email",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (back()))
                      }, _cache[18] || (_cache[18] = [
                        _createElementVNode("use", { href: _hoisted_1 }, null, -1)
                      ])))
                    ]),
                    _createElementVNode("span", null, _toDisplayString(_unref(email_subject)), 1)
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mail), (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "light-square",
                        key: index
                      }, [
                        (item.icon == 'mail-trash')
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              href: "javascript:;",
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (trash()))
                            }, [
                              (_openBlock(), _createElementBlock("svg", {
                                class: _normalizeClass(item.svgclass)
                              }, [
                                _createElementVNode("use", {
                                  "xlink:href": 
                              require('@/assets/svg/icon-sprite.svg') + `#${item.icon}`
                            
                                }, null, 8, _hoisted_15)
                              ], 2))
                            ]))
                          : (_openBlock(), _createElementBlock("svg", {
                              key: 1,
                              class: _normalizeClass(item.svgclass)
                            }, [
                              _createElementVNode("use", {
                                "xlink:href": 
                            require('@/assets/svg/icon-sprite.svg') + `#${item.icon}`
                          
                              }, null, 8, _hoisted_16)
                            ], 2))
                      ]))
                    }), 128))
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", null, [
                        _cache[27] || (_cache[27] = _createElementVNode("div", { class: "rounded-border" }, [
                          _createElementVNode("img", {
                            class: "img-fluid",
                            src: _imports_1,
                            alt: "user"
                          })
                        ], -1)),
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("p", null, _toDisplayString(_unref(email_from_name)), 1),
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("button", _hoisted_22, [
                              _createTextVNode(" To " + _toDisplayString(_unref(email_to_email) == "martin@easyreg.ca"
                                ? "Me"
                                : _unref(email_to_email)) + " ", 1),
                              _createVNode(_component_vue_feather, { type: "chevron-down" })
                            ]),
                            _createElementVNode("div", _hoisted_23, [
                              _createElementVNode("p", null, [
                                _cache[19] || (_cache[19] = _createTextVNode(" From : ")),
                                _createElementVNode("span", null, [
                                  _createElementVNode("strong", null, _toDisplayString(_unref(email_from_name)), 1),
                                  _createTextVNode(" <" + _toDisplayString(_unref(email_from_email)) + ">", 1)
                                ])
                              ]),
                              (_unref(email_reply_email))
                                ? (_openBlock(), _createElementBlock("p", _hoisted_24, [
                                    _cache[20] || (_cache[20] = _createTextVNode(" reply-to :")),
                                    _createElementVNode("span", null, "<" + _toDisplayString(_unref(email_reply_email)) + ">", 1)
                                  ]))
                                : _createCommentVNode("", true),
                              _createElementVNode("p", null, [
                                _cache[21] || (_cache[21] = _createTextVNode(" to : ")),
                                _createElementVNode("span", null, _toDisplayString(_unref(email_to_email)), 1)
                              ]),
                              (_unref(email_cc_emails).length > 0)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_25, [
                                    _cache[22] || (_cache[22] = _createTextVNode(" cc : ")),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(email_cc_emails), (cc, cc_index) => {
                                      return (_openBlock(), _createElementBlock("span", {
                                        key: `cc-${cc_index}`
                                      }, _toDisplayString(cc.cc_email), 1))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true),
                              (_unref(email_bcc_emails).length > 0)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_26, [
                                    _cache[23] || (_cache[23] = _createTextVNode(" bcc : ")),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(email_bcc_emails), (bcc, bcc_index) => {
                                      return (_openBlock(), _createElementBlock("span", {
                                        key: `bcc-${bcc_index}`
                                      }, _toDisplayString(bcc.bcc_name) + " " + _toDisplayString(bcc.bcc_email), 1))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true),
                              (_unref(email_date))
                                ? (_openBlock(), _createElementBlock("p", _hoisted_27, [
                                    _cache[24] || (_cache[24] = _createTextVNode(" date : ")),
                                    _createElementVNode("span", null, _toDisplayString(_unref(email_date)), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              _createElementVNode("p", null, [
                                _cache[25] || (_cache[25] = _createTextVNode(" subject : ")),
                                _createElementVNode("span", null, _toDisplayString(_unref(email_subject)), 1)
                              ]),
                              _cache[26] || (_cache[26] = _createElementVNode("p", null, [
                                _createTextVNode("security : "),
                                _createElementVNode("span", null, "standard encryption (TLS)")
                              ], -1))
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        (_unref(email_date))
                          ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(_unref(email_date)), 1))
                          : _createCommentVNode("", true),
                        _cache[28] || (_cache[28] = _createElementVNode("div", {
                          class: "light-square",
                          onclick: "window.print();"
                        }, [
                          _createElementVNode("svg", null, [
                            _createElementVNode("use", { href: _hoisted_2 })
                          ])
                        ], -1)),
                        _cache[29] || (_cache[29] = _createElementVNode("div", { class: "light-square btn-group" }, [
                          _createElementVNode("svg", {
                            class: "dropdown-toggle",
                            "data-bs-toggle": "dropdown",
                            "aria-expanded": "false"
                          }, [
                            _createElementVNode("use", { href: _hoisted_3 })
                          ]),
                          _createElementVNode("div", { class: "dropdown-menu dropdown-block" }, [
                            _createElementVNode("a", {
                              class: "dropdown-item",
                              href: "#!"
                            }, [
                              _createElementVNode("i", { class: "fa fa-mail-reply" }),
                              _createTextVNode("Reply")
                            ]),
                            _createElementVNode("a", {
                              class: "dropdown-item",
                              href: "#!"
                            }, [
                              _createElementVNode("i", { class: "fa fa-mail-forward" }),
                              _createTextVNode("Forward")
                            ])
                          ])
                        ], -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("div", _hoisted_31, [
                        _createElementVNode("iframe", {
                          id: "frame",
                          width: "100%",
                          height: "100%",
                          ref_key: "emailBodyRef",
                          ref: emailBodyRef,
                          srcdoc: _unref(email_body)
                        }, null, 8, _hoisted_32)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      (_unref(email_attachments).length > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_34, _cache[30] || (_cache[30] = [
                            _createElementVNode("svg", null, [
                              _createElementVNode("use", { href: _hoisted_4 })
                            ], -1),
                            _createElementVNode("span", { class: "f-light" }, "Attachments", -1)
                          ])))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_35, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(email_attachments), (attachment, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: `attachment-${index}`,
                            class: "d-inline-block gap-2"
                          }, [
                            _createElementVNode("div", _hoisted_36, [
                              _createElementVNode("div", _hoisted_37, [
                                _cache[31] || (_cache[31] = _createElementVNode("img", {
                                  src: _imports_2,
                                  style: {"width":"20px","height":"20px"},
                                  alt: "pdf"
                                }, null, -1)),
                                _createElementVNode("div", _hoisted_38, [
                                  _createElementVNode("p", null, _toDisplayString(attachment.attachments_filename), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.$filters.filesize(attachment.attachments_size)), 1)
                                ])
                              ]),
                              _createElementVNode("a", {
                                href: "javascript:;",
                                download: "download",
                                onClick: _withModifiers(($event: any) => (
                                handleDownload(
                                  attachment.attachments_filename,
                                  attachment.attachments_id
                                )
                              ), ["stop"])
                              }, _cache[32] || (_cache[32] = [
                                _createElementVNode("i", { class: "fa fa-download f-light" }, null, -1)
                              ]), 8, _hoisted_39)
                            ])
                          ]))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_40, [
                      (_unref(email_reply_email) != '' || _unref(email_from_email) != '')
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            type: "button",
                            onClick: _withModifiers(handleReply, ["stop"]),
                            class: _normalizeClass(["btn btn-pill btn-light text-dark btn-reply", _unref(reply_section) ? 'active' : ''])
                          }, " Reply ", 2))
                        : _createCommentVNode("", true),
                      (_unref(email_cc_emails).length > 0)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 1,
                            type: "button",
                            onClick: _withModifiers(handleReplyAll, ["stop"]),
                            class: _normalizeClass(["btn btn-pill btn-light text-dark btn-reply-all", _unref(reply_section) ? 'active' : ''])
                          }, " Reply All ", 2))
                        : _createCommentVNode("", true),
                      _createElementVNode("button", {
                        type: "button",
                        onClick: _withModifiers(handleForward, ["stop"]),
                        class: _normalizeClass(["btn btn-pill btn-light text-dark btn-forward", _unref(forwarded_email) ? 'active' : ''])
                      }, " Forward ", 2)
                    ]),
                    (_unref(reply_section) || _unref(forwarded_email))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                          _createElementVNode("div", _hoisted_42, [
                            _createElementVNode("div", _hoisted_43, [
                              _createElementVNode("div", _hoisted_44, [
                                _createElementVNode("div", _hoisted_45, [
                                  _cache[33] || (_cache[33] = _createElementVNode("label", null, " From ", -1)),
                                  _createElementVNode("button", {
                                    type: "button",
                                    class: _normalizeClass(["btn btn-sm", 
                                  _unref(send_from_email) == 'martin@easyreg.ca'
                                    ? 'btn-primary text-light'
                                    : 'btn-light text-dark'
                                ]),
                                    onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_isRef(send_from_email) //@ts-ignore
 ? send_from_email.value = 'martin@easyreg.ca' : send_from_email = 'martin@easyreg.ca'), ["stop"]))
                                  }, " martin@easyreg.ca ", 2),
                                  _createElementVNode("button", {
                                    type: "button",
                                    class: _normalizeClass(["btn btn-sm", 
                                  _unref(send_from_email) == 'help@easyreg.ca'
                                    ? 'btn-primary text-light'
                                    : 'btn-light text-dark'
                                ]),
                                    onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_isRef(send_from_email) //@ts-ignore
 ? send_from_email.value = 'help@easyreg.ca' : send_from_email = 'help@easyreg.ca'), ["stop"]))
                                  }, " help@easyreg.ca ", 2)
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_46, [
                              _createElementVNode("div", _hoisted_47, [
                                _createElementVNode("div", _hoisted_48, [
                                  _createElementVNode("div", _hoisted_49, [
                                    _cache[35] || (_cache[35] = _createElementVNode("label", null, " To ", -1)),
                                    _createVNode(_unref(VueMultiselect), {
                                      modelValue: _unref(send_to_emails),
                                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(send_to_emails) ? (send_to_emails).value = $event : send_to_emails = $event)),
                                      options: _unref(to_searched_result),
                                      multiple: true,
                                      taggable: true,
                                      loading: _unref(isToLoading),
                                      searchable: true,
                                      onTag: _cache[6] || (_cache[6] = ($event: any) => (addNewToEmail($event, 'to'))),
                                      onSearchChange: _unref(search),
                                      placeholder: "Type to search by email, firstname, lastname",
                                      label: "email",
                                      "track-by": "code"
                                    }, {
                                      option: _withCtx((props) => [
                                        (props.option)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                                              (props.option.type == 'Attendee')
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_51, _toDisplayString(props.option.type) + " - " + _toDisplayString(props.option.firstname) + " " + _toDisplayString(props.option.lastname) + " " + _toDisplayString(`<${props.option.email}>`) + " " + _toDisplayString(props.option.event_name) + " " + _toDisplayString(props.option.event_date), 1))
                                                : _createCommentVNode("", true),
                                              (props.option.type == 'User')
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_52, " Client - " + _toDisplayString(props.option.client_abbreviation) + " - " + _toDisplayString(props.option.client_name) + " - " + _toDisplayString(props.option.firstname) + " " + _toDisplayString(props.option.lastname) + " " + _toDisplayString(`<${props.option.email}>`), 1))
                                                : _createCommentVNode("", true),
                                              (props.option.type == 'Unknown')
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_53, _toDisplayString(props.option.type) + " - " + _toDisplayString(props.option.firstname) + " " + _toDisplayString(props.option.lastname) + " " + _toDisplayString(`<${props.option.email}>`), 1))
                                                : _createCommentVNode("", true)
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      noResult: _withCtx(() => _cache[34] || (_cache[34] = [
                                        _createTextVNode(" Oops! No elements found. Consider changing the search query. ")
                                      ])),
                                      _: 1
                                    }, 8, ["modelValue", "options", "loading", "onSearchChange"])
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_54, [
                                _createElementVNode("div", _hoisted_55, [
                                  _createElementVNode("div", _hoisted_56, [
                                    _cache[37] || (_cache[37] = _createElementVNode("label", null, " CC ", -1)),
                                    _createVNode(_unref(VueMultiselect), {
                                      modelValue: _unref(send_cc_emails),
                                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_isRef(send_cc_emails) ? (send_cc_emails).value = $event : send_cc_emails = $event)),
                                      options: _unref(cc_searched_result),
                                      loading: _unref(isCCLoading),
                                      multiple: true,
                                      taggable: true,
                                      searchable: true,
                                      onTag: _cache[8] || (_cache[8] = ($event: any) => (addNewToEmail($event, 'cc'))),
                                      onSearchChange: _unref(searchCC),
                                      placeholder: "Type to search by email, firstname, lastname",
                                      label: "email",
                                      "track-by": "code"
                                    }, {
                                      option: _withCtx((props) => [
                                        (props.option)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                                              (props.option.type == 'Attendee')
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_58, _toDisplayString(props.option.type) + " - " + _toDisplayString(props.option.firstname) + " " + _toDisplayString(props.option.lastname) + " " + _toDisplayString(`<${props.option.email}>`) + " " + _toDisplayString(props.option.event_name) + " " + _toDisplayString(props.option.event_date), 1))
                                                : _createCommentVNode("", true),
                                              (props.option.type == 'User')
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_59, " Client - " + _toDisplayString(props.option.client_abbreviation) + " - " + _toDisplayString(props.option.client_name) + " - " + _toDisplayString(props.option.firstname) + " " + _toDisplayString(props.option.lastname) + " " + _toDisplayString(`<${props.option.email}>`), 1))
                                                : _createCommentVNode("", true),
                                              (props.option.type == 'Unknown')
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_60, _toDisplayString(props.option.type) + " - " + _toDisplayString(props.option.firstname) + " " + _toDisplayString(props.option.lastname) + " " + _toDisplayString(`<${props.option.email}>`), 1))
                                                : _createCommentVNode("", true)
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      noResult: _withCtx(() => _cache[36] || (_cache[36] = [
                                        _createTextVNode(" Oops! No elements found. Consider changing the search query. ")
                                      ])),
                                      _: 1
                                    }, 8, ["modelValue", "options", "loading", "onSearchChange"])
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_61, [
                                _createElementVNode("div", _hoisted_62, [
                                  _createElementVNode("div", _hoisted_63, [
                                    _cache[39] || (_cache[39] = _createElementVNode("label", null, " BCC ", -1)),
                                    _createVNode(_unref(VueMultiselect), {
                                      modelValue: _unref(send_bcc_emails),
                                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_isRef(send_bcc_emails) ? (send_bcc_emails).value = $event : send_bcc_emails = $event)),
                                      options: _unref(bcc_searched_result),
                                      loading: _unref(isBCCLoading),
                                      multiple: true,
                                      taggable: true,
                                      searchable: true,
                                      onTag: _cache[10] || (_cache[10] = ($event: any) => (addNewToEmail($event, 'bcc'))),
                                      onSearchChange: _unref(searchBCC),
                                      placeholder: "Type to search by email, firstname, lastname",
                                      label: "email",
                                      "track-by": "code"
                                    }, {
                                      option: _withCtx((props) => [
                                        (props.option)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                                              (props.option.type == 'Attendee')
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_65, _toDisplayString(props.option.type) + " - " + _toDisplayString(props.option.firstname) + " " + _toDisplayString(props.option.lastname) + " " + _toDisplayString(`<${props.option.email}>`) + " " + _toDisplayString(props.option.event_name) + " " + _toDisplayString(props.option.event_date), 1))
                                                : _createCommentVNode("", true),
                                              (props.option.type == 'User')
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_66, " Client - " + _toDisplayString(props.option.client_abbreviation) + " - " + _toDisplayString(props.option.client_name) + " - " + _toDisplayString(props.option.firstname) + " " + _toDisplayString(props.option.lastname) + " " + _toDisplayString(`<${props.option.email}>`), 1))
                                                : _createCommentVNode("", true),
                                              (props.option.type == 'Unknown')
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_67, _toDisplayString(props.option.type) + " - " + _toDisplayString(props.option.firstname) + " " + _toDisplayString(props.option.lastname) + " " + _toDisplayString(`<${props.option.email}>`), 1))
                                                : _createCommentVNode("", true)
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      noResult: _withCtx(() => _cache[38] || (_cache[38] = [
                                        _createTextVNode(" Oops! No elements found. Consider changing the search query. ")
                                      ])),
                                      _: 1
                                    }, 8, ["modelValue", "options", "loading", "onSearchChange"])
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_68, [
                              _createElementVNode("div", _hoisted_69, [
                                _createElementVNode("div", _hoisted_70, [
                                  _cache[40] || (_cache[40] = _createElementVNode("label", { class: "col-form-label" }, "Subject", -1)),
                                  _withDirectives(_createElementVNode("input", {
                                    class: "form-control",
                                    id: "composeFrom",
                                    type: "text",
                                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_isRef(to_subject) ? (to_subject).value = $event : to_subject = $event))
                                  }, null, 512), [
                                    [_vModelText, _unref(to_subject)]
                                  ])
                                ])
                              ])
                            ]),
                            _cache[45] || (_cache[45] = _createElementVNode("div", { class: "editor-wrapper" }, [
                              _createElementVNode("div", {
                                id: "editor-container",
                                class: "w-full"
                              })
                            ], -1)),
                            _createElementVNode("div", _hoisted_71, [
                              _createElementVNode("div", _hoisted_72, [
                                _createElementVNode("div", _hoisted_73, [
                                  _cache[42] || (_cache[42] = _createElementVNode("label", { class: "col-form-label m-r-10" }, "Close", -1)),
                                  _createElementVNode("div", _hoisted_74, [
                                    _createElementVNode("label", _hoisted_75, [
                                      _withDirectives(_createElementVNode("input", {
                                        type: "checkbox",
                                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_isRef(email_closed) ? (email_closed).value = $event : email_closed = $event))
                                      }, null, 512), [
                                        [_vModelCheckbox, _unref(email_closed)]
                                      ]),
                                      _cache[41] || (_cache[41] = _createElementVNode("span", { class: "switch-state" }, null, -1))
                                    ])
                                  ])
                                ])
                              ]),
                              (_unref(email_easyreg).length == 1 && _unref(email_easyreg)[0].status == 'A')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_76, [
                                    _createElementVNode("div", _hoisted_77, [
                                      _cache[44] || (_cache[44] = _createElementVNode("label", { class: "col-form-label m-r-10" }, "Receipt", -1)),
                                      _createElementVNode("div", _hoisted_78, [
                                        _createElementVNode("label", _hoisted_79, [
                                          _withDirectives(_createElementVNode("input", {
                                            type: "checkbox",
                                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_isRef(email_receipt) ? (email_receipt).value = $event : email_receipt = $event))
                                          }, null, 512), [
                                            [_vModelCheckbox, _unref(email_receipt)]
                                          ]),
                                          _cache[43] || (_cache[43] = _createElementVNode("span", { class: "switch-state" }, null, -1))
                                        ])
                                      ])
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_80, [
                            _createElementVNode("div", _hoisted_81, [
                              _createElementVNode("div", _hoisted_82, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(send_attachments_attached), (send_attachment, send_attachment_index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: `attachment-attached-index-${send_attachment_index}`,
                                    class: "d-flex justify-content-between gap-2 attachment-item"
                                  }, [
                                    _createElementVNode("div", _hoisted_83, [
                                      _createElementVNode("span", _hoisted_84, _toDisplayString(send_attachment.name), 1),
                                      _createElementVNode("span", null, "(" + _toDisplayString(_ctx.$filters.filesize(send_attachment.size)) + ")", 1)
                                    ]),
                                    _createElementVNode("button", {
                                      class: "btn-close btn-sm",
                                      onClick: _withModifiers(($event: any) => (handleRemoveAttachment(send_attachment)), ["stop"])
                                    }, null, 8, _hoisted_85)
                                  ]))
                                }), 128)),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(send_attachments), (send_attachment, send_attachment_index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: `attachment-index-${send_attachment_index}`,
                                    class: "d-flex justify-content-between gap-2 attachment-item"
                                  }, [
                                    _createElementVNode("div", _hoisted_86, [
                                      _createElementVNode("span", _hoisted_87, _toDisplayString(send_attachment.name), 1),
                                      _createElementVNode("span", null, "(" + _toDisplayString(_ctx.$filters.filesize(send_attachment.size)) + ")", 1)
                                    ]),
                                    _createElementVNode("button", {
                                      class: "btn-close btn-sm",
                                      onClick: _withModifiers(($event: any) => (handleRemoveAttachment(send_attachment)), ["stop"])
                                    }, null, 8, _hoisted_88)
                                  ]))
                                }), 128))
                              ])
                            ]),
                            _createElementVNode("div", { class: "send-btn d-inline-flex gap-2 justify-content-end" }, [
                              _createElementVNode("button", {
                                class: "btn btn-secondary",
                                type: "button",
                                onClick: handleAttachFiles
                              }, _cache[46] || (_cache[46] = [
                                _createTextVNode(" Attach files"),
                                _createElementVNode("i", { class: "fa-solid fa-paperclip" }, null, -1)
                              ])),
                              _createElementVNode("button", {
                                class: "btn btn-primary",
                                type: "button",
                                onClick: handleSend
                              }, _cache[47] || (_cache[47] = [
                                _createTextVNode(" Send"),
                                _createElementVNode("i", { class: "fa-solid fa-paper-plane" }, null, -1)
                              ]))
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_89, [
            _createElementVNode("div", _hoisted_90, [
              _createElementVNode("div", _hoisted_91, [
                _createElementVNode("div", _hoisted_92, [
                  _cache[49] || (_cache[49] = _createElementVNode("i", { class: "search-bg iconly-Search icli" }, null, -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "demo-input py-0 Typeahead-input form-control-plaintext w-100",
                    type: "text",
                    placeholder: "Search for last name, email address, and code.",
                    title: "",
                    onKeyup: searchTerm,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (_isRef(keyword) ? (keyword).value = $event : keyword = $event))
                  }, null, 544), [
                    [_vModelText, _unref(keyword)]
                  ]),
                  (_unref(keyword) != '')
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        onClick: _cache[15] || (_cache[15] = _withModifiers(($event: any) => (_isRef(keyword) //@ts-ignore
 ? keyword.value = '' : keyword = ''), ["stop"])),
                        class: "cursor-pointer close-btn d-flex align-items-center"
                      }, _cache[48] || (_cache[48] = [
                        _createElementVNode("svg", {
                          xmlns: "http://www.w3.org/2000/svg",
                          className: "h-6 w-6",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          stroke: "currentColor"
                        }, [
                          _createElementVNode("path", {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "3",
                            d: "M6 18L18 6M6 6l12 12"
                          })
                        ], -1)
                      ])))
                    : _createCommentVNode("", true)
                ])
              ]),
              (_unref(searched_email_easyreg).length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_93, [
                    _cache[52] || (_cache[52] = _createElementVNode("div", { class: "p-2" }, [
                      _createElementVNode("span", { class: "text" }, "Search Results")
                    ], -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(searched_email_easyreg), (email_easyreg_item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `searched-email-easyreg-item-${index}`,
                        class: "d-flex justify-content-between align-items-start p-2 assigned-wrapper"
                      }, [
                        _createElementVNode("div", _hoisted_94, [
                          _createElementVNode("div", _hoisted_95, [
                            _createElementVNode("span", _hoisted_96, _toDisplayString(email_easyreg_item.firstname) + " " + _toDisplayString(email_easyreg_item.lastname), 1),
                            _cache[50] || (_cache[50] = _createElementVNode("div", { class: "badge badge-primary" }, "Attendee", -1))
                          ]),
                          _createElementVNode("div", _hoisted_97, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["badge", getStatusColor(email_easyreg_item.status)])
                            }, _toDisplayString(getStatus(email_easyreg_item.status)), 3)
                          ]),
                          _createElementVNode("div", _hoisted_98, _toDisplayString(email_easyreg_item.event_name), 1),
                          _createElementVNode("div", null, _toDisplayString(email_easyreg_item.client_abbreviation), 1)
                        ]),
                        _createElementVNode("div", null, [
                          _createElementVNode("span", _hoisted_99, [
                            _createElementVNode("div", null, [
                              _createElementVNode("strong", null, _toDisplayString(email_easyreg_item.code), 1),
                              _createElementVNode("span", {
                                onClick: _withModifiers(($event: any) => (handleCopyCode(email_easyreg_item.code)), ["stop"])
                              }, [
                                (_openBlock(), _createElementBlock("svg", _hoisted_101, _cache[51] || (_cache[51] = [
                                  _createElementVNode("path", { d: "M224 0c-35.3 0-64 28.7-64 64V288c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224zM64 160c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V384H288v64H64V224h64V160H64z" }, null, -1)
                                ])))
                              ], 8, _hoisted_100)
                            ]),
                            _createElementVNode("div", _hoisted_102, [
                              _createElementVNode("button", {
                                type: "button",
                                onClick: _withModifiers(($event: any) => (handleAssign(email_easyreg_item)), ["stop"]),
                                class: "btn btn-md btn-light text-dark btn-receipt"
                              }, " Assign ", 8, _hoisted_103)
                            ])
                          ])
                        ])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_104, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(email_easyreg), (email_easyreg_item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `email-easyreg-item-${index}`,
                    class: "d-flex justify-content-between align-items-start p-2 assigned-wrapper"
                  }, [
                    _createElementVNode("div", _hoisted_105, [
                      _createElementVNode("div", _hoisted_106, [
                        _createElementVNode("span", _hoisted_107, _toDisplayString(email_easyreg_item.name), 1),
                        _cache[53] || (_cache[53] = _createElementVNode("div", { class: "badge badge-primary" }, "Attendee", -1))
                      ]),
                      _createElementVNode("div", _hoisted_108, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["badge", getStatusColor(email_easyreg_item.status)])
                        }, _toDisplayString(getStatus(email_easyreg_item.status)), 3)
                      ]),
                      _createElementVNode("div", _hoisted_109, _toDisplayString(email_easyreg_item.event_name), 1),
                      _createElementVNode("div", null, _toDisplayString(email_easyreg_item.client_abbreviation), 1),
                      (email_easyreg_item.status != 'W')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_110, [
                            _createElementVNode("button", {
                              type: "button",
                              class: "btn btn-md btn-light text-dark btn-receipt",
                              onClick: _withModifiers(($event: any) => (
                            handleReceipt(
                              email_easyreg_item.code,
                              email_easyreg_item.client_code,
                              email_easyreg_item.event_code
                            )
                          ), ["stop"])
                            }, " Receipt ", 8, _hoisted_111)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", _hoisted_112, [
                        _createElementVNode("strong", null, _toDisplayString(email_easyreg_item.code), 1),
                        _createElementVNode("span", {
                          onClick: _withModifiers(($event: any) => (handleCopyCode(email_easyreg_item.code)), ["stop"])
                        }, [
                          (_openBlock(), _createElementBlock("svg", _hoisted_114, _cache[54] || (_cache[54] = [
                            _createElementVNode("path", { d: "M224 0c-35.3 0-64 28.7-64 64V288c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224zM64 160c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V384H288v64H64V224h64V160H64z" }, null, -1)
                          ])))
                        ], 8, _hoisted_113)
                      ])
                    ])
                  ]))
                }), 128))
              ]),
              _cache[57] || (_cache[57] = _createElementVNode("div", { class: "p-2 pb-0 article-wrapper" }, [
                _createElementVNode("span", { class: "text" }, "Search Articles")
              ], -1)),
              _createElementVNode("div", _hoisted_115, [
                _createElementVNode("div", _hoisted_116, [
                  _cache[56] || (_cache[56] = _createElementVNode("i", { class: "search-bg iconly-Search icli" }, null, -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "demo-input py-0 Typeahead-input form-control-plaintext w-100",
                    type: "text",
                    placeholder: "Search for keywords or title",
                    title: "",
                    onKeyup: searchArticles,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (_isRef(keyword_for_article) ? (keyword_for_article).value = $event : keyword_for_article = $event))
                  }, null, 544), [
                    [_vModelText, _unref(keyword_for_article)]
                  ]),
                  (_unref(keyword_for_article) != '')
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        onClick: _cache[17] || (_cache[17] = _withModifiers(($event: any) => (_isRef(keyword_for_article) //@ts-ignore
 ? keyword_for_article.value = '' : keyword_for_article = ''), ["stop"])),
                        class: "cursor-pointer close-btn d-flex align-items-center"
                      }, _cache[55] || (_cache[55] = [
                        _createElementVNode("svg", {
                          xmlns: "http://www.w3.org/2000/svg",
                          className: "h-6 w-6",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          stroke: "currentColor"
                        }, [
                          _createElementVNode("path", {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "3",
                            d: "M6 18L18 6M6 6l12 12"
                          })
                        ], -1)
                      ])))
                    : _createCommentVNode("", true)
                ])
              ]),
              (_unref(searched_articles).length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_117, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(searched_articles), (searched_article, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `searched-article-${index}`,
                        class: "flex flex-column align-items-start p-2 article-searched-wrapper"
                      }, [
                        _createElementVNode("div", _hoisted_118, [
                          _createElementVNode("div", _hoisted_119, [
                            _createElementVNode("span", _hoisted_120, _toDisplayString(searched_article.article_headline), 1),
                            _createElementVNode("button", {
                              type: "button",
                              onClick: _withModifiers(($event: any) => (handleInsert(searched_article.article_id)), ["stop"]),
                              class: "btn btn-md btn-light text-dark btn-receipt"
                            }, " Insert ", 8, _hoisted_121)
                          ])
                        ]),
                        _createElementVNode("div", null, [
                          _createElementVNode("span", _hoisted_122, [
                            _createElementVNode("div", {
                              innerHTML: searched_article.article_text
                            }, null, 8, _hoisted_123)
                          ])
                        ])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _createElementVNode("input", {
        type: "file",
        accept: "*",
        hidden: "",
        ref_key: "uploadRef",
        ref: uploadRef,
        onChange: uploadFile
      }, null, 544)
    ])
  ], 64))
}
}

})