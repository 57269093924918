<template>
    <Breadcrumbs main="Knowledgebase" mains="Knowledgebase" title="Knowledgebase" />
    <div class="container-fluid">
        <div class="row">
            <BrowseArticles />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const BrowseArticles = defineAsyncComponent(() => import("@/components/theme/knowledgebase/BrowseArticles.vue"))
</script>
