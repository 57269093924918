import ApiInstance from "./ApiBase";

export const GetRules = async (page: number): Promise<any> => {
    const res = await ApiInstance.get(`/rules/${page}`);
    return res?.data;
};

export const GetRule = async (id: any): Promise<any> => {
    const res = await ApiInstance.get(`/rule/${id}`);
    return res?.data;
};

export const ManageRules = async (rule_id: any, rule_action: string, rule_type: string, rule_text: string, rule_category: string): Promise<any> => {
    const data = new FormData();
    data.append("rule_id", rule_id);
    data.append("rule_action", rule_action);
    data.append("rule_type", rule_type);
    data.append("rule_text", rule_text);
    data.append("rule_category", rule_category);
    const res = await ApiInstance.post(`/manage-rule`, data);
    return res?.data;
};
