import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "email-wrap email-main-wrapper" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xxl-12 col-xl-12 box-col-12" }
const _hoisted_5 = { class: "email-right-aside" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "table-responsive theme-scrollbar" }
const _hoisted_9 = { class: "table table-dashed" }
const _hoisted_10 = { class: "d-flex gap-2" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]

import { defineAsyncComponent, onMounted, ref } from "vue";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import { GetCategories, ManageCategories } from "@/services/ApiCategories";
import { openModal } from "@kolirt/vue-modal";
import { useRouter } from "vue-router";

// Define refs to hold rule data and current pagination state

export default /*@__PURE__*/_defineComponent({
  __name: 'IndexCategories',
  setup(__props) {

const allCategories = ref<any[]>([]); // Stores all loaded rules

// Import component for rule management modal
const ManageCategory = defineAsyncComponent(
  () => import("@/components/modal/ManageCategory.vue")
);

// Router instance for navigation if needed
const router = useRouter();

/**
 * Opens modal for creating a new rule.
 */
function handleCreate() {
  openModal(ManageCategory, { category_id: "", edit_type: "create" })
    .then(() => {
      // Reload rules after editing to reflect changes
      loadCategories();
    })
    .catch(() => {
      console.log("Edit modal closed without saving.");
    });
}

/**
 * Opens modal for editing an existing rule.
 * @param rule_id - ID of the rule to edit
 */
function handleEdit(category_id: string) {
  openModal(ManageCategory, { category_id, edit_type: "update" })
    .then(() => {
      // Reload rules after editing to reflect changes
      loadCategories();
    })
    .catch(() => {
      console.log("Edit modal closed without saving.");
    });
}

/**
 * Handle deleting a rule.
 * @param rule_id - ID of the rule to delete
 */
async function handleDelete(category_id: string) {
  await ManageCategories(category_id, "", "delete");
  loadCategories();
}

/**
 * Refreshes the rules list by resetting pagination and clearing data.
 */
function loadCategories() {
  (async () => {
    try {
      const { rows } = await GetCategories();
      allCategories.value = rows;
    } catch (error) {}
  })();
}
onMounted(() => {
  loadCategories();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", { class: "container-fluid" }, [
      _createElementVNode("div", { class: "page-title" }, [
        _createElementVNode("div", { class: "row" }, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-sm-6 col-12" }, [
            _createElementVNode("h2", null, "Categories")
          ], -1)),
          _createElementVNode("div", { class: "col-sm-6 col-12 d-flex justify-content-end" }, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-md btn-primary text-light",
              onClick: handleCreate
            }, " Create ")
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("table", _hoisted_9, [
                      _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", { scope: "col" }),
                          _createElementVNode("th", { scope: "col" }, "Category")
                        ])
                      ], -1)),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allCategories.value, (category, index) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: `category-${index}`
                          }, [
                            _createElementVNode("td", null, [
                              _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-sm btn-light text-dark btn-action",
                                  onClick: _withModifiers(($event: any) => (handleEdit(category.category_id)), ["stop"])
                                }, " Edit ", 8, _hoisted_11),
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-sm btn-danger text-white btn-action",
                                  onClick: _withModifiers(($event: any) => (handleDelete(category.category_id)), ["stop"])
                                }, " Delete ", 8, _hoisted_12)
                              ])
                            ]),
                            _createElementVNode("td", null, _toDisplayString(category.category_name), 1)
                          ]))
                        }), 128))
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})