<template>
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-12 p-0">
        <div class="login-card login-dark">
          <div>
            <div>
              <router-link class="logo" to="/"
                ><img
                  class="img-fluid for-light m-auto"
                  src="@/assets/images/logo/logo1.png"
                  alt="looginpage" /><img
                  class="img-fluid for-dark"
                  src="@/assets/images/logo/logo-dark.png"
                  alt="logo"
              /></router-link>
            </div>
            <div class="login-main">
              <form class="theme-form">
                <h2 class="text-center">Sign in to account</h2>
                <p class="text-center">
                  Enter your email &amp; password to login
                </p>
                <div class="form-group">
                  <label class="col-form-label">Email Address</label>
                  <input
                    class="form-control"
                    type="email"
                    required
                    v-model="email"
                    placeholder="Test@gmail.com"
                  />
                </div>
                <div class="form-group">
                  <label class="col-form-label">Password</label>
                  <div class="form-input position-relative">
                    <input
                      class="form-control"
                      v-model="password"
                      :type="type"
                      name="login[password]"
                      required
                      placeholder="*********"
                    />
                    <div class="show-hide">
                      <span
                        :class="type == 'password' ? 'show' : ''"
                        @click="showPassword"
                      >
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-0 checkbox-checked">
                  <div class="text-end mt-3">
                    <button
                      class="btn btn-primary btn-block w-100"
                      type="button"
                      @click="doLogin"
                    >
                      Sign in
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useRouter } from "vue-router";
let type = ref<string>("password");
let email = ref<string>("");
let password = ref<string>("");
let router = useRouter();
function showPassword() {
  if (type.value === "password") {
    type.value = "text";
  } else {
    type.value = "password";
  }
}
import { UserLogin } from "@/services/ApiUser";
import { useUserStore } from "@/store/user";
const user = useUserStore();
function doLogin() {
  (async () => {
    try {
      const res = await UserLogin({
        email: email.value,
        password: password.value,
      });
      user.SET_AUTH_TOKEN(res.token);
      localStorage.setItem("SidebarType", "compact-wrapper");
      router.push("/");
      toast.success("Login Successfully ", {
        position: "top-right",
        autoClose: 2000,
      });
    } catch (e) {
      console.log("e", e);
    }
  })();
}
</script>