<template>
  <div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-sm-6 col-12">
          <h2>Articles</h2>
        </div>
      </div>
    </div>
  </div>
  <!-- Main Content Area -->
  <div class="container-fluid">
    <div class="email-wrap email-main-wrapper">
      <form @submit.prevent="save">
        <!-- Radio buttons for selecting rule type -->

        <div class="row mb-3 g-3">
          <div class="col-sm-12 px-0 py-0">
            <div class="card-wrapper py-0">
              <label class="form-label" for="article_event_type"
                >Event Type</label
              >
              <div class="d-flex gap-4">
                <div class="common-flex switch-wrapper icon-switch">
                  <div class="d-flex">
                    <label class="col-form-label m-r-10">Online</label>
                    <label class="switch">
                      <input
                        type="checkbox"
                        v-model="article_type_online"
                      /><span class="switch-state"></span>
                    </label>
                  </div>
                </div>
                <div class="common-flex switch-wrapper icon-switch">
                  <div class="d-flex">
                    <label class="col-form-label m-r-10">In-Person</label>
                    <label class="switch">
                      <input
                        type="checkbox"
                        v-model="article_type_inperson"
                      /><span class="switch-state"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Input for entering rule text -->
        <div class="row mb-3 g-3">
          <div class="col-sm-12 px-0 py-0">
            <div class="card-wrapper py-0 position-relative">
              <label class="form-label" for="article_headline">Headline</label>
              <input
                class="form-control"
                id="article_headline"
                type="text"
                v-bind:class="
                  formSubmitted
                    ? errors.includes('article_headline')
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
                v-model="article_headline"
              />
              <div
                class="invalid-tooltip"
                v-if="errors.includes('article_headline')"
              >
                Headline is required
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 g-3">
          <div class="col-sm-12 px-0 py-0">
            <div class="card-wrapper py-0 position-relative">
              <label class="form-label" for="article_content">Content</label>
              <froala
                id="edit"
                :tag="'textarea'"
                :config="config"
                v-model:value="article_content"
                v-bind:class="
                  formSubmitted
                    ? errors.includes('article_content')
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
              ></froala>
              <div
                class="invalid-tooltip"
                v-if="errors.includes('article_content')"
              >
                Content is required
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 g-3">
          <div class="col-sm-12 px-0 py-0">
            <div class="card-wrapper py-0">
              <label class="form-label" for="article_keywords">Keywords</label>
              <input
                class="form-control"
                id="article_keywords"
                type="text"
                v-model="article_keywords"
              />
            </div>
          </div>
        </div>
        <div class="row mb-3 g-3">
          <div class="col-sm-12 px-0 py-0">
            <div class="card-wrapper py-0 position-relative">
              <label class="form-label" for="article_categories"
                >Categories</label
              >
              <VueMultiselect
                v-model="article_categories"
                :options="categories"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                tag-placeholder="Add this as new tag"
                placeholder="Type to search category or add new category"
                label="text"
                track-by="id"
                v-bind:class="
                  formSubmitted
                    ? errors.includes('article_categories')
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
              />
              <div
                class="invalid-tooltip"
                v-if="errors.includes('article_categories')"
              >
                Category is required
              </div>
            </div>
          </div>
        </div>
      </form>

      <!-- Action buttons: Cancel and Save -->
      <div class="d-flex justify-content-end gap-2 mb-5">
        <button class="btn btn-primary" type="button" @click="close()">
          Cancel
        </button>
        <button
          class="btn btn-primary"
          :disabled="showLoader"
          type="button"
          @click="save"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* Custom styles for action buttons */
.btn-action {
  white-space: nowrap;
  border: 1px solid #a20000;

  &:hover,
  &:focus {
    border: 1px solid #a20000;
  }
}
</style>


<script lang="ts" setup>
import VueMultiselect from "vue-multiselect";
import { defineProps, ref, onMounted, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { GetArticle, ManageArticles } from "@/services/ApiArticles";
import { GetCategories } from "@/services/ApiCategories";
const config = {
  height: "350px",
  events: {
    initialized: function () {
      console.log("initialized");
    },
  },
};
let router = useRouter();
const route = useRoute();
watch(
  () => route.params.article_id,
  () => {
    init();
  }
);
let article_type_online = ref<boolean>(false);
let article_type_inperson = ref<boolean>(false);
let article_headline = ref<string>("");
let article_keywords = ref<string>("");
let article_content = ref<string>("");
let article_categories = ref<any>([]);
let categories = ref<any>([]);
let formSubmitted = ref<boolean>(false); // Loader state for async operations
const errors = ref<string[]>([]); // Array to hold validation errors
const showLoader = ref<boolean>(false); // Loader state for async operations
async function close() {
  router.push("/administration/manage-articles/list");
}
// Function to save the rule, handles form validation and API call
async function save() {
  try {
    formSubmitted.value = true;
    errors.value = []; // Clear previous errors
    if (!article_headline.value) errors.value.push("article_headline");

    if (article_content.value == "") {
      errors.value.push("article_content");
    }

    if (article_categories.value.length == 0) {
      errors.value.push("article_categories");
    }

    if (errors.value.length > 0) {
      return;
    }

    showLoader.value = true; // Show loader while saving

    // Call API to save the rule
    await ManageArticles(
      route.params.article_id,
      "update",
      article_type_online.value ? "Y" : "N",
      article_type_inperson.value ? "Y" : "N",
      article_headline.value,
      article_content.value,
      article_keywords.value,
      JSON.stringify(article_categories.value)
    );

    // On success, close loader and show confirmation modal
    showLoader.value = false;
    router.push("/administration/manage-articles/list");
  } catch (e) {
    console.error("SaveRule error", e); // Log error for debugging
    showLoader.value = false; // Reset loader on error
  }
}
function addTag(tag: any) {
  const new_tag = {
    text: tag,
    id: "NEW",
  };
  categories.value.push(new_tag);
  article_categories.value.push(new_tag);
}
function init() {
  (async () => {
    try {
      const { rows } = await GetCategories();
      categories.value = rows.map((c) => {
        return { id: c.category_id, text: c.category_name };
      });
      formSubmitted.value = false;
      article_headline.value = "";
      article_content.value = "";
      article_content.value = "";
      if (route.params.article_id != "BLANK") {
        const {
          id,
          type_online,
          type_inperson,
          headline,
          content,
          keywords,
          categories,
        } = await GetArticle(route.params.article_id.toString());
        article_headline.value = headline;
        article_content.value = content;
        article_keywords.value = keywords;
        article_categories.value = categories;
        article_type_inperson.value = type_inperson == "Y" ? true : false;
        article_type_online.value = type_online == "Y" ? true : false;
      }
    } catch (e) {
      console.log("init error", e);
    }
  })();
}
// Load rule on mount if an existing rule ID is provided
onMounted(() => {
  init();
});
</script>
