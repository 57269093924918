import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "page-title" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-6 col-12 d-flex justify-content-end" }
const _hoisted_5 = { class: "container-fluid" }
const _hoisted_6 = { class: "email-wrap email-main-wrapper" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-xxl-12 col-xl-12 box-col-12" }
const _hoisted_9 = { class: "email-right-aside" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-sm-12 col-lg-12 col-xl-12" }
const _hoisted_12 = { class: "table-responsive theme-scrollbar" }
const _hoisted_13 = { class: "table table-dashed" }
const _hoisted_14 = { class: "d-flex gap-2" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["onClick"]

import { defineAsyncComponent, onMounted, ref } from "vue";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import { DeleteEmailByTemplate, GetEmailTemplates } from "@/services/ApiCore";
import { openModal } from "@kolirt/vue-modal";
import { useRoute, useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'IndexTemplates',
  setup(__props) {

let currentPage = ref<number>(1);
let allData = ref<any[]>([]);
let router = useRouter();
const ManageTemplate = defineAsyncComponent(
  () => import("@/components/modal/ManageTemplate.vue")
);
function handleContent(template_id: string) {
  router.push(`/administration/templates-easyreg/edit/${template_id}`);
}
function handleEdit(template_id: string, edit_type: string) {
  openModal(ManageTemplate, {
    template_id,
    edit_type,
  })
    // runs when modal is closed via confirmModal
    .then((data) => {
      console.log("success", data);
      load();
    })
    // runs when modal is closed via closeModal or esc
    .catch(() => {
      console.log("catch");
    });
}
async function handleDelete(template_id: string) {
  await DeleteEmailByTemplate(template_id);
  load();
}
function handleCreate() {
  openModal(ManageTemplate, {
    template_id: "NEW",
    edit_type: "NEW",
  })
    // runs when modal is closed via confirmModal
    .then((data) => {
      console.log("success", data);
      load();
    })
    // runs when modal is closed via closeModal or esc
    .catch(() => {
      console.log("catch");
    });
}
const load = async () => {
  try {
    const { rows, total } = await GetEmailTemplates(currentPage.value, "all");
    allData.value = rows;
  } catch (error) {}
};
onMounted(() => {
  (async () => {
    load();
  })();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-sm-6 col-12" }, [
            _createElementVNode("h2", null, "Templates")
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-md btn-primary text-light",
              onClick: _withModifiers(handleCreate, ["stop"])
            }, " Create ")
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("table", _hoisted_13, [
                      _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", { scope: "col" }, "Action"),
                          _createElementVNode("th", { scope: "col" }, "Subject"),
                          _createElementVNode("th", { scope: "col" }, "Description")
                        ])
                      ], -1)),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(allData), (template, index) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: `template-${index}`
                          }, [
                            _createElementVNode("td", null, [
                              _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-sm btn-light text-dark btn-action",
                                  onClick: _withModifiers(($event: any) => (
                                handleEdit(template.template_ID, 'edit')
                              ), ["stop"])
                                }, " Edit Subject ", 8, _hoisted_15),
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-sm btn-light text-dark btn-action",
                                  onClick: _withModifiers(($event: any) => (handleContent(template.template_ID)), ["stop"])
                                }, " Edit Content ", 8, _hoisted_16),
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-sm btn-light text-dark btn-action",
                                  onClick: _withModifiers(($event: any) => (
                                handleEdit(template.template_ID, 'clone')
                              ), ["stop"])
                                }, " Clone Content ", 8, _hoisted_17),
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-sm btn-danger text-white btn-action",
                                  onClick: _withModifiers(($event: any) => (handleDelete(template.template_ID)), ["stop"])
                                }, " Delete ", 8, _hoisted_18)
                              ])
                            ]),
                            _createElementVNode("td", null, _toDisplayString(template.template_subject), 1),
                            _createElementVNode("td", null, _toDisplayString(template.template_description), 1)
                          ]))
                        }), 128))
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})