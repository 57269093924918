import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logo/logo1.png'
import _imports_1 from '@/assets/images/logo/logo-dark.png'


const _hoisted_1 = { class: "container-fluid p-0" }
const _hoisted_2 = { class: "row m-0" }
const _hoisted_3 = { class: "col-12 p-0" }
const _hoisted_4 = { class: "login-card login-dark" }
const _hoisted_5 = { class: "login-main" }
const _hoisted_6 = { class: "theme-form" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "form-input position-relative" }
const _hoisted_10 = ["type"]
const _hoisted_11 = { class: "show-hide" }

import { ref } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useRouter } from "vue-router";
import { UserLogin } from "@/services/ApiUser";
import { useUserStore } from "@/store/user";

export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  setup(__props) {

let type = ref<string>("password");
let email = ref<string>("");
let password = ref<string>("");
let router = useRouter();
function showPassword() {
  if (type.value === "password") {
    type.value = "text";
  } else {
    type.value = "password";
  }
}
const user = useUserStore();
function doLogin() {
  (async () => {
    try {
      const res = await UserLogin({
        email: email.value,
        password: password.value,
      });
      user.SET_AUTH_TOKEN(res.token);
      localStorage.setItem("SidebarType", "compact-wrapper");
      router.push("/");
      toast.success("Login Successfully ", {
        position: "top-right",
        autoClose: 2000,
      });
    } catch (e) {
      console.log("e", e);
    }
  })();
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createVNode(_component_router_link, {
                class: "logo",
                to: "/"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("img", {
                    class: "img-fluid for-light m-auto",
                    src: _imports_0,
                    alt: "looginpage"
                  }, null, -1),
                  _createElementVNode("img", {
                    class: "img-fluid for-dark",
                    src: _imports_1,
                    alt: "logo"
                  }, null, -1)
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("form", _hoisted_6, [
                _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "text-center" }, "Sign in to account", -1)),
                _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-center" }, " Enter your email & password to login ", -1)),
                _createElementVNode("div", _hoisted_7, [
                  _cache[3] || (_cache[3] = _createElementVNode("label", { class: "col-form-label" }, "Email Address", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "form-control",
                    type: "email",
                    required: "",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(email) ? (email).value = $event : email = $event)),
                    placeholder: "Test@gmail.com"
                  }, null, 512), [
                    [_vModelText, _unref(email)]
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _cache[4] || (_cache[4] = _createElementVNode("label", { class: "col-form-label" }, "Password", -1)),
                  _createElementVNode("div", _hoisted_9, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-control",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(password) ? (password).value = $event : password = $event)),
                      type: _unref(type),
                      name: "login[password]",
                      required: "",
                      placeholder: "*********"
                    }, null, 8, _hoisted_10), [
                      [_vModelDynamic, _unref(password)]
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("span", {
                        class: _normalizeClass(_unref(type) == 'password' ? 'show' : ''),
                        onClick: showPassword
                      }, null, 2)
                    ])
                  ])
                ]),
                _createElementVNode("div", { class: "form-group mb-0 checkbox-checked" }, [
                  _createElementVNode("div", { class: "text-end mt-3" }, [
                    _createElementVNode("button", {
                      class: "btn btn-primary btn-block w-100",
                      type: "button",
                      onClick: doLogin
                    }, " Sign in ")
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})