<template>
  <!-- Page Header -->
  <loading
    v-model:active="isPrint"
    :can-cancel="true"
    :is-full-page="true"
    :height="40"
    :width="40"
    :loader="`dots`"
    :color="`#2B5582`"
    :background-color="`#FFFFFF`"
  >
    <span class="loading-text">Please wait...</span>
  </loading>
  <div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-sm-6 col-12">
          <h2>Articles</h2>
        </div>
        <div class="col-sm-6 col-12 d-flex gap-2 justify-content-end">
          <!-- Button to open create rule modal -->
          <button
            type="button"
            class="btn btn-md btn-primary text-light d-flex align-items-center gap-2 position-relative"
            @click="handlePrint"
          >
            Print PDF
          </button>
          <button
            type="button"
            class="btn btn-md btn-primary text-light"
            @click="handleCreate"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Main Content Area -->
  <div class="container-fluid">
    <div class="email-wrap email-main-wrapper">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 box-col-12">
          <div class="email-right-aside">
            <div class="row mb-2">
              <div class="col-6">
                <VueMultiselect
                  v-model="search_category"
                  :options="categories"
                  :multiple="true"
                  :taggable="true"
                  :searchable="true"
                  @Select="handleSelectCategory"
                  @Remove="handleSelectCategory"
                  label="text"
                  track-by="id"
                >
                  <template #option="props">
                    <template v-if="props.option">
                      <div class="option__desc">
                        <span class="option__title">
                          {{ props.option.text }}
                        </span>
                      </div>
                    </template>
                  </template>
                  <template #noResult>
                    Oops! No elements found. Consider changing the search query.
                  </template>
                </VueMultiselect>
              </div>
              <div class="col-6">
                <VueMultiselect
                  v-model="search_event"
                  :options="events"
                  :multiple="true"
                  :taggable="true"
                  @Select="handleSelectEvent"
                  @Remove="handleSelectEvent"
                  :searchable="true"
                  label="text"
                  track-by="id"
                >
                  <template #option="props">
                    <template v-if="props.option">
                      <div class="option__desc">
                        <span class="option__title">
                          {{ props.option.text }}
                        </span>
                      </div>
                    </template>
                  </template>
                  <template #noResult>
                    Oops! No elements found. Consider changing the search query.
                  </template>
                </VueMultiselect>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <!-- Table to display rules -->
                <div class="table-responsive theme-scrollbar">
                  <table class="table table-dashed">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Article</th>
                        <th scope="col">Categories</th>
                        <th scope="col">In-Person Events</th>
                        <th scope="col">Online Events</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Loop through rules data to populate table rows -->
                      <tr
                        v-for="(article, index) in allArticles"
                        :key="`article-${index}`"
                      >
                        <td>
                          <div class="d-flex gap-2">
                            <!-- Edit Button -->
                            <button
                              type="button"
                              class="btn btn-sm btn-light text-dark btn-action"
                              @click.stop="handleEdit(article.article_id)"
                            >
                              Edit
                            </button>
                            <!-- Delete Button -->
                            <button
                              type="button"
                              class="btn btn-sm btn-danger text-white btn-action"
                              @click.stop="handleDelete(article.article_id)"
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                        <!-- Display rule explanation text -->
                        <td>{{ article.article_headline }}</td>

                        <td>
                          <template
                            v-for="(
                              category, index
                            ) in article.article_categories"
                            :key="`article-category-${index}`"
                          >
                            <span class="badge badge-primary">{{
                              category
                            }}</span>
                          </template>
                        </td>
                        <td class="items-center align-top">
                          <svg v-if="article.article_type_online=='Y'" xmlns="http://www.w3.org/2000/svg" height="24" width="18" viewBox="0 0 384 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#1b8b04" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                          <svg v-if="article.article_type_online=='N'" xmlns="http://www.w3.org/2000/svg" height="20" width="15" viewBox="0 0 384 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#e40101" d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                        </td>
                        <td class="items-center align-top">
                          <svg v-if="article.article_type_inperson=='Y'" xmlns="http://www.w3.org/2000/svg" height="24" width="18" viewBox="0 0 384 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#1b8b04" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                          <svg v-if="article.article_type_inperson=='N'" xmlns="http://www.w3.org/2000/svg" height="20" width="15" viewBox="0 0 384 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#e40101" d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-text {
  font-size: 28px;
}
.animate {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* Custom styles for action buttons */
.btn-action {
  white-space: nowrap;
  border: 1px solid #a20000;

  &:hover,
  &:focus {
    border: 1px solid #a20000;
  }
}
</style>

<script lang="ts" setup>
import VueMultiselect from "vue-multiselect";
import { defineAsyncComponent, onMounted, ref } from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { GetArticles, ManageArticles } from "@/services/ApiArticles";
import { openModal } from "@kolirt/vue-modal";
import { useRouter } from "vue-router";
import { GetCategories } from "@/services/ApiCategories";
let categories = ref<any>([]);
let search_category = ref<any>([]);
let search_event = ref<any>([]);
let isPrint = ref<boolean>(false);
let filters = {
  categories: "All",
  event_type: "All",
};
// Define refs to hold rule data and current pagination state
const allArticles = ref<any[]>([]); // Stores all loaded rules

// Import component for rule management modal
const ManageArticle = defineAsyncComponent(
  () => import("@/components/modal/ManageArticle.vue")
);
const events = [
  { id: "All", text: "All" },
  { id: "I", text: "In-Person" },
  { id: "O", text: "Online" },
];
// Router instance for navigation if needed
const router = useRouter();

/**
 * Opens modal for creating a new rule.
 */
function handleCreate() {
  router.push("/administration/manage-articles/edit/BLANK");
}

/**
 * Opens modal for editing an existing rule.
 * @param rule_id - ID of the rule to edit
 */
function handleEdit(article_id: string) {
  router.push(`/administration/manage-articles/edit/${article_id}`);
}

/**
 * Handle deleting a rule.
 * @param rule_id - ID of the rule to delete
 */
async function handleDelete(article_id: string) {
  await ManageArticles(article_id, "delete");
  loadArticles();
}
/**
 * Refreshes the rules list by resetting pagination and clearing data.
 */
function handleSelectEvent() {
  console.log(search_event.value);
  let search_event_joined = search_event.value.map((x: any) => x.id).join(",");
  filters.event_type = search_event_joined;
  loadArticles();
}
function handleSelectCategory() {
  console.log(search_category.value);
  let search_category_joined = search_category.value
    .map((x: any) => x.id)
    .join(",");
  filters.categories = search_category_joined;
  loadArticles();
}
function handlePrint() {
  (async () => {
    try {
      let anchor = document.createElement("a");
      document.body.appendChild(anchor);
      isPrint.value = true;
      let file = `https://crm-api.easyreg.cloud/api/article-pdf`;
      fetch(file)
        .then((response) => response.blob())
        .then((blobby) => {
          let objectUrl = window.URL.createObjectURL(blobby);

          anchor.href = objectUrl;
          anchor.download = `article.pdf`;
          anchor.click();
          window.URL.revokeObjectURL(objectUrl);
          isPrint.value = false;
        });
    } catch (e) {
      isPrint.value = false;
      console.log("handlePrint error", e);
    }
  })();
}
function loadCategories() {
  (async () => {
    try {
      const { rows } = await GetCategories();
      categories.value = rows.map((c) => {
        return { id: c.category_id, text: c.category_name };
      });

      categories.value.unshift({
        id: -1,
        text: "All",
      });
    } catch (error) {}
  })();
}
function loadArticles() {
  (async () => {
    try {
      const { rows } = await GetArticles("all", 1, filters);
      allArticles.value = rows;
    } catch (error) {
      console.log("error", error);
    }
  })();
}
onMounted(() => {
  loadCategories();
  filters.categories = -1;
  filters.event_type = "All";
  loadArticles();
});
</script>
