import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid email-wrap bookmark-wrap todo-wrap" }
const _hoisted_2 = { class: "row" }

import { defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'IndexTodo',
  setup(__props) {

const TodoSidebar = defineAsyncComponent(() => import("@/components/theme/todo/TodoSidebar.vue"))
const AddTask = defineAsyncComponent(() => import("@/components/theme/todo/AddTask.vue"))


return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Apps",
      mains: "To-Do",
      title: "To-Do"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(TodoSidebar)),
        _createVNode(_unref(AddTask))
      ])
    ])
  ], 64))
}
}

})