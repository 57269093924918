import ApiInstance from "./ApiBase";

export const GetArticles = async (type: string, keyword: any, filters: any): Promise<any> => {


    const filterKeys: string[] = Object.keys(filters);
    let queryArr: string[] = [];
    queryArr.push(`type=${type}`);
    queryArr.push(`keyword=${keyword}`);

    if (filterKeys.length > 0) {
        filterKeys.forEach((key) => {
            if (filters[key] != '') {
                const query = key + "=" + filters[key];
                queryArr.push(query);
            }
        });
    }

    let query = queryArr.length > 0 ? "?" + queryArr.join("&") : "";
    const res = await ApiInstance.get(`/articles${query}`);
    return res?.data;
};

export const GetArticle = async (id: any): Promise<any> => {
    const res = await ApiInstance.get(`/article/${id}`);
    return res?.data;
};

export const ManageArticles = async (article_id: any, action: string, article_type_online: string = 'N', article_type_inperson: string = 'N', article_headline: string = '', article_content: string = '', article_keywords: string = '', article_categories: string): Promise<any> => {
    const data = new FormData();
    data.append("article_id", article_id);
    data.append("action", action);
    data.append("article_type_online", article_type_online);
    data.append("article_type_inperson", article_type_inperson);
    data.append("article_headline", article_headline);
    data.append("article_content", article_content);
    data.append("article_keywords", article_keywords);
    data.append("article_categories", article_categories);
    const res = await ApiInstance.post(`/manage-article`, data);
    return res?.data;
};
