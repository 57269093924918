import { toast } from "vue3-toastify";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { transformErrors } from "./Utils";
import HttpStatusCodes from "@/services/HttpStatusCodes";
const AUTH_TOKEN_KEY = "easyREGCRMToken";
const AuthInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const accessToken = localStorage.getItem(AUTH_TOKEN_KEY) || "";
  config.headers = config.headers ?? {};
  if (accessToken)
    config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
};

const instance: Readonly<AxiosInstance> = axios.create({
  baseURL: process.env.apiUrl,
  timeout: -1,
});
const OnResponseSuccess = (response: AxiosResponse<any>): AxiosResponse<any> =>
  response;
const OnResponseFailure = async (error: any): Promise<any> => {
  const httpStatus = error?.response?.status;
  const errors = transformErrors(error?.response?.data?.errors);
  const isUnknownError = errors?.[0].startsWith("Unknown");
  console.log('httpStatus', httpStatus);
  switch (httpStatus) {
    case HttpStatusCodes.UNAUTHORIZED:
      // userStore.logout();
      if (error?.response?.data?.error === "Login Error") {
        toast.error("Opps... Invalid email and password ", {
          position: "top-right",
          autoClose: 2000,
        });
        // return Promise.resolve();
      } else if (
        error?.response?.data?.message === "Unauthenticated." ||
        error?.response?.data?.error === "Unknown error!."
      ) {
        window.onbeforeunload = null;
        window.location.reload();
      } else {
      }

      break;
    // case HttpStatusCodes.BAD_REQUEST:
    //   return Promise.resolve();
    //   break;
    default:
      break;
  }
  return Promise.reject(errors);
};

instance.defaults.headers.get.Accepts = "application/json";
instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
instance.interceptors.request.use(AuthInterceptor);
instance.interceptors.response.use(OnResponseSuccess, OnResponseFailure);

export default instance;
