import ApiInstance from "./ApiBase";

export const GetCategories = async (): Promise<any> => {
    const res = await ApiInstance.get(`/categories`);
    return res?.data;
};

export const GetCategory = async (id: any): Promise<any> => {
    const res = await ApiInstance.get(`/category/${id}`);
    return res?.data;
};

export const ManageCategories = async (category_id: any, category_name: string, action: string): Promise<any> => {
    const data = new FormData();
    data.append("category_id", category_id);
    data.append("action", action);
    data.append("category_name", category_name);
    const res = await ApiInstance.post(`/manage-category`, data);
    return res?.data;
};
