import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "email-wrap email-main-wrapper" }
const _hoisted_3 = { class: "row mb-3 g-3" }
const _hoisted_4 = { class: "col-sm-12 px-0 py-0" }
const _hoisted_5 = { class: "card-wrapper py-0" }
const _hoisted_6 = { class: "d-flex gap-4" }
const _hoisted_7 = { class: "common-flex switch-wrapper icon-switch" }
const _hoisted_8 = { class: "d-flex" }
const _hoisted_9 = { class: "switch" }
const _hoisted_10 = { class: "common-flex switch-wrapper icon-switch" }
const _hoisted_11 = { class: "d-flex" }
const _hoisted_12 = { class: "switch" }
const _hoisted_13 = { class: "row mb-3 g-3" }
const _hoisted_14 = { class: "col-sm-12 px-0 py-0" }
const _hoisted_15 = { class: "card-wrapper py-0 position-relative" }
const _hoisted_16 = {
  key: 0,
  class: "invalid-tooltip"
}
const _hoisted_17 = { class: "row mb-3 g-3" }
const _hoisted_18 = { class: "col-sm-12 px-0 py-0" }
const _hoisted_19 = { class: "card-wrapper py-0 position-relative" }
const _hoisted_20 = {
  key: 0,
  class: "invalid-tooltip"
}
const _hoisted_21 = { class: "row mb-3 g-3" }
const _hoisted_22 = { class: "col-sm-12 px-0 py-0" }
const _hoisted_23 = { class: "card-wrapper py-0" }
const _hoisted_24 = { class: "row mb-3 g-3" }
const _hoisted_25 = { class: "col-sm-12 px-0 py-0" }
const _hoisted_26 = { class: "card-wrapper py-0 position-relative" }
const _hoisted_27 = {
  key: 0,
  class: "invalid-tooltip"
}
const _hoisted_28 = { class: "d-flex justify-content-end gap-2 mb-5" }
const _hoisted_29 = ["disabled"]

import VueMultiselect from "vue-multiselect";
import { ref, onMounted, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { GetArticle, ManageArticles } from "@/services/ApiArticles";
import { GetCategories } from "@/services/ApiCategories";

export default /*@__PURE__*/_defineComponent({
  __name: 'SaveArticle',
  setup(__props) {

const config = {
  height: "350px",
  events: {
    initialized: function () {
      console.log("initialized");
    },
  },
};
let router = useRouter();
const route = useRoute();
watch(
  () => route.params.article_id,
  () => {
    init();
  }
);
let article_type_online = ref<boolean>(false);
let article_type_inperson = ref<boolean>(false);
let article_headline = ref<string>("");
let article_keywords = ref<string>("");
let article_content = ref<string>("");
let article_categories = ref<any>([]);
let categories = ref<any>([]);
let formSubmitted = ref<boolean>(false); // Loader state for async operations
const errors = ref<string[]>([]); // Array to hold validation errors
const showLoader = ref<boolean>(false); // Loader state for async operations
async function close() {
  router.push("/administration/manage-articles/list");
}
// Function to save the rule, handles form validation and API call
async function save() {
  try {
    formSubmitted.value = true;
    errors.value = []; // Clear previous errors
    if (!article_headline.value) errors.value.push("article_headline");

    if (article_content.value == "") {
      errors.value.push("article_content");
    }

    if (article_categories.value.length == 0) {
      errors.value.push("article_categories");
    }

    if (errors.value.length > 0) {
      return;
    }

    showLoader.value = true; // Show loader while saving

    // Call API to save the rule
    await ManageArticles(
      route.params.article_id,
      "update",
      article_type_online.value ? "Y" : "N",
      article_type_inperson.value ? "Y" : "N",
      article_headline.value,
      article_content.value,
      article_keywords.value,
      JSON.stringify(article_categories.value)
    );

    // On success, close loader and show confirmation modal
    showLoader.value = false;
    router.push("/administration/manage-articles/list");
  } catch (e) {
    console.error("SaveRule error", e); // Log error for debugging
    showLoader.value = false; // Reset loader on error
  }
}
function addTag(tag: any) {
  const new_tag = {
    text: tag,
    id: "NEW",
  };
  categories.value.push(new_tag);
  article_categories.value.push(new_tag);
}
function init() {
  (async () => {
    try {
      const { rows } = await GetCategories();
      categories.value = rows.map((c) => {
        return { id: c.category_id, text: c.category_name };
      });
      formSubmitted.value = false;
      article_headline.value = "";
      article_content.value = "";
      article_content.value = "";
      if (route.params.article_id != "BLANK") {
        const {
          id,
          type_online,
          type_inperson,
          headline,
          content,
          keywords,
          categories,
        } = await GetArticle(route.params.article_id.toString());
        article_headline.value = headline;
        article_content.value = content;
        article_keywords.value = keywords;
        article_categories.value = categories;
        article_type_inperson.value = type_inperson == "Y" ? true : false;
        article_type_online.value = type_online == "Y" ? true : false;
      }
    } catch (e) {
      console.log("init error", e);
    }
  })();
}
// Load rule on mount if an existing rule ID is provided
onMounted(() => {
  init();
});

return (_ctx: any,_cache: any) => {
  const _component_froala = _resolveComponent("froala")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "container-fluid" }, [
      _createElementVNode("div", { class: "page-title" }, [
        _createElementVNode("div", { class: "row" }, [
          _createElementVNode("div", { class: "col-sm-6 col-12" }, [
            _createElementVNode("h2", null, "Articles")
          ])
        ])
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("form", {
          onSubmit: _withModifiers(save, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[11] || (_cache[11] = _createElementVNode("label", {
                  class: "form-label",
                  for: "article_event_type"
                }, "Event Type", -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[8] || (_cache[8] = _createElementVNode("label", { class: "col-form-label m-r-10" }, "Online", -1)),
                      _createElementVNode("label", _hoisted_9, [
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(article_type_online) ? (article_type_online).value = $event : article_type_online = $event))
                        }, null, 512), [
                          [_vModelCheckbox, _unref(article_type_online)]
                        ]),
                        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "switch-state" }, null, -1))
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _cache[10] || (_cache[10] = _createElementVNode("label", { class: "col-form-label m-r-10" }, "In-Person", -1)),
                      _createElementVNode("label", _hoisted_12, [
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(article_type_inperson) ? (article_type_inperson).value = $event : article_type_inperson = $event))
                        }, null, 512), [
                          [_vModelCheckbox, _unref(article_type_inperson)]
                        ]),
                        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "switch-state" }, null, -1))
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _cache[12] || (_cache[12] = _createElementVNode("label", {
                  class: "form-label",
                  for: "article_headline"
                }, "Headline", -1)),
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass(["form-control", 
                  _unref(formSubmitted)
                    ? errors.value.includes('article_headline')
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                ]),
                  id: "article_headline",
                  type: "text",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(article_headline) ? (article_headline).value = $event : article_headline = $event))
                }, null, 2), [
                  [_vModelText, _unref(article_headline)]
                ]),
                (errors.value.includes('article_headline'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, " Headline is required "))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _cache[13] || (_cache[13] = _createElementVNode("label", {
                  class: "form-label",
                  for: "article_content"
                }, "Content", -1)),
                _createVNode(_component_froala, {
                  id: "edit",
                  tag: 'textarea',
                  config: config,
                  value: _unref(article_content),
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_isRef(article_content) ? (article_content).value = $event : article_content = $event)),
                  class: _normalizeClass(
                  _unref(formSubmitted)
                    ? errors.value.includes('article_content')
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                )
                }, null, 8, ["value", "class"]),
                (errors.value.includes('article_content'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, " Content is required "))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _cache[14] || (_cache[14] = _createElementVNode("label", {
                  class: "form-label",
                  for: "article_keywords"
                }, "Keywords", -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  id: "article_keywords",
                  type: "text",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(article_keywords) ? (article_keywords).value = $event : article_keywords = $event))
                }, null, 512), [
                  [_vModelText, _unref(article_keywords)]
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _cache[15] || (_cache[15] = _createElementVNode("label", {
                  class: "form-label",
                  for: "article_categories"
                }, "Categories", -1)),
                _createVNode(_unref(VueMultiselect), {
                  modelValue: _unref(article_categories),
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(article_categories) ? (article_categories).value = $event : article_categories = $event)),
                  options: _unref(categories),
                  multiple: true,
                  taggable: true,
                  onTag: addTag,
                  "tag-placeholder": "Add this as new tag",
                  placeholder: "Type to search category or add new category",
                  label: "text",
                  "track-by": "id",
                  class: _normalizeClass(
                  _unref(formSubmitted)
                    ? errors.value.includes('article_categories')
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                )
                }, null, 8, ["modelValue", "options", "class"]),
                (errors.value.includes('article_categories'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_27, " Category is required "))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ], 32),
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("button", {
            class: "btn btn-primary",
            type: "button",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (close()))
          }, " Cancel "),
          _createElementVNode("button", {
            class: "btn btn-primary",
            disabled: showLoader.value,
            type: "button",
            onClick: save
          }, " Save ", 8, _hoisted_29)
        ])
      ])
    ])
  ], 64))
}
}

})