import {
  IUser,
  IUserLoginRequestParams,
} from "./models";
import ApiInstance from "./ApiBase";

export const UserLogin = async (
  params: IUserLoginRequestParams
): Promise<IUser> => {
  const res = await ApiInstance.post(`/login`, params);
  return res?.data as IUser;
};
