import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "email-wrap email-main-wrapper" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xxl-12 col-xl-12 box-col-12" }
const _hoisted_5 = { class: "email-right-aside" }
const _hoisted_6 = { class: "row mb-2" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = {
  key: 0,
  class: "option__desc"
}
const _hoisted_9 = { class: "option__title" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = {
  key: 0,
  class: "option__desc"
}
const _hoisted_12 = { class: "option__title" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-12" }
const _hoisted_15 = { class: "table-responsive theme-scrollbar" }
const _hoisted_16 = { class: "table table-dashed" }
const _hoisted_17 = { class: "d-flex gap-2" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "items-center align-top" }
const _hoisted_21 = {
  key: 0,
  xmlns: "http://www.w3.org/2000/svg",
  height: "24",
  width: "18",
  viewBox: "0 0 384 512"
}
const _hoisted_22 = {
  key: 1,
  xmlns: "http://www.w3.org/2000/svg",
  height: "20",
  width: "15",
  viewBox: "0 0 384 512"
}
const _hoisted_23 = { class: "items-center align-top" }
const _hoisted_24 = {
  key: 0,
  xmlns: "http://www.w3.org/2000/svg",
  height: "24",
  width: "18",
  viewBox: "0 0 384 512"
}
const _hoisted_25 = {
  key: 1,
  xmlns: "http://www.w3.org/2000/svg",
  height: "20",
  width: "15",
  viewBox: "0 0 384 512"
}

import VueMultiselect from "vue-multiselect";
import { defineAsyncComponent, onMounted, ref } from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { GetArticles, ManageArticles } from "@/services/ApiArticles";
import { openModal } from "@kolirt/vue-modal";
import { useRouter } from "vue-router";
import { GetCategories } from "@/services/ApiCategories";

export default /*@__PURE__*/_defineComponent({
  __name: 'IndexArticles',
  setup(__props) {

let categories = ref<any>([]);
let search_category = ref<any>([]);
let search_event = ref<any>([]);
let isPrint = ref<boolean>(false);
let filters = {
  categories: "All",
  event_type: "All",
};
// Define refs to hold rule data and current pagination state
const allArticles = ref<any[]>([]); // Stores all loaded rules

// Import component for rule management modal
const ManageArticle = defineAsyncComponent(
  () => import("@/components/modal/ManageArticle.vue")
);
const events = [
  { id: "All", text: "All" },
  { id: "I", text: "In-Person" },
  { id: "O", text: "Online" },
];
// Router instance for navigation if needed
const router = useRouter();

/**
 * Opens modal for creating a new rule.
 */
function handleCreate() {
  router.push("/administration/manage-articles/edit/BLANK");
}

/**
 * Opens modal for editing an existing rule.
 * @param rule_id - ID of the rule to edit
 */
function handleEdit(article_id: string) {
  router.push(`/administration/manage-articles/edit/${article_id}`);
}

/**
 * Handle deleting a rule.
 * @param rule_id - ID of the rule to delete
 */
async function handleDelete(article_id: string) {
  await ManageArticles(article_id, "delete");
  loadArticles();
}
/**
 * Refreshes the rules list by resetting pagination and clearing data.
 */
function handleSelectEvent() {
  console.log(search_event.value);
  let search_event_joined = search_event.value.map((x: any) => x.id).join(",");
  filters.event_type = search_event_joined;
  loadArticles();
}
function handleSelectCategory() {
  console.log(search_category.value);
  let search_category_joined = search_category.value
    .map((x: any) => x.id)
    .join(",");
  filters.categories = search_category_joined;
  loadArticles();
}
function handlePrint() {
  (async () => {
    try {
      let anchor = document.createElement("a");
      document.body.appendChild(anchor);
      isPrint.value = true;
      let file = `https://crm-api.easyreg.cloud/api/article-pdf`;
      fetch(file)
        .then((response) => response.blob())
        .then((blobby) => {
          let objectUrl = window.URL.createObjectURL(blobby);

          anchor.href = objectUrl;
          anchor.download = `article.pdf`;
          anchor.click();
          window.URL.revokeObjectURL(objectUrl);
          isPrint.value = false;
        });
    } catch (e) {
      isPrint.value = false;
      console.log("handlePrint error", e);
    }
  })();
}
function loadCategories() {
  (async () => {
    try {
      const { rows } = await GetCategories();
      categories.value = rows.map((c) => {
        return { id: c.category_id, text: c.category_name };
      });

      categories.value.unshift({
        id: -1,
        text: "All",
      });
    } catch (error) {}
  })();
}
function loadArticles() {
  (async () => {
    try {
      const { rows } = await GetArticles("all", 1, filters);
      allArticles.value = rows;
    } catch (error) {
      console.log("error", error);
    }
  })();
}
onMounted(() => {
  loadCategories();
  filters.categories = -1;
  filters.event_type = "All";
  loadArticles();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Loading), {
      active: _unref(isPrint),
      "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => (_isRef(isPrint) ? (isPrint).value = $event : isPrint = $event)),
      "can-cancel": true,
      "is-full-page": true,
      height: 40,
      width: 40,
      loader: `dots`,
      color: `#2B5582`,
      "background-color": `#FFFFFF`
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [
        _createElementVNode("span", { class: "loading-text" }, "Please wait...", -1)
      ])),
      _: 1
    }, 8, ["active"]),
    _createElementVNode("div", { class: "container-fluid" }, [
      _createElementVNode("div", { class: "page-title" }, [
        _createElementVNode("div", { class: "row" }, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col-sm-6 col-12" }, [
            _createElementVNode("h2", null, "Articles")
          ], -1)),
          _createElementVNode("div", { class: "col-sm-6 col-12 d-flex gap-2 justify-content-end" }, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-md btn-primary text-light d-flex align-items-center gap-2 position-relative",
              onClick: handlePrint
            }, " Print PDF "),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-md btn-primary text-light",
              onClick: handleCreate
            }, " Create ")
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_unref(VueMultiselect), {
                    modelValue: _unref(search_category),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(search_category) ? (search_category).value = $event : search_category = $event)),
                    options: _unref(categories),
                    multiple: true,
                    taggable: true,
                    searchable: true,
                    onSelect: handleSelectCategory,
                    onRemove: handleSelectCategory,
                    label: "text",
                    "track-by": "id"
                  }, {
                    option: _withCtx((props) => [
                      (props.option)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("span", _hoisted_9, _toDisplayString(props.option.text), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    noResult: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" Oops! No elements found. Consider changing the search query. ")
                    ])),
                    _: 1
                  }, 8, ["modelValue", "options"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_unref(VueMultiselect), {
                    modelValue: _unref(search_event),
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(search_event) ? (search_event).value = $event : search_event = $event)),
                    options: events,
                    multiple: true,
                    taggable: true,
                    onSelect: handleSelectEvent,
                    onRemove: handleSelectEvent,
                    searchable: true,
                    label: "text",
                    "track-by": "id"
                  }, {
                    option: _withCtx((props) => [
                      (props.option)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createElementVNode("span", _hoisted_12, _toDisplayString(props.option.text), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    noResult: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" Oops! No elements found. Consider changing the search query. ")
                    ])),
                    _: 1
                  }, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("table", _hoisted_16, [
                      _cache[11] || (_cache[11] = _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", { scope: "col" }),
                          _createElementVNode("th", { scope: "col" }, "Article"),
                          _createElementVNode("th", { scope: "col" }, "Categories"),
                          _createElementVNode("th", { scope: "col" }, "In-Person Events"),
                          _createElementVNode("th", { scope: "col" }, "Online Events")
                        ])
                      ], -1)),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allArticles.value, (article, index) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: `article-${index}`
                          }, [
                            _createElementVNode("td", null, [
                              _createElementVNode("div", _hoisted_17, [
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-sm btn-light text-dark btn-action",
                                  onClick: _withModifiers(($event: any) => (handleEdit(article.article_id)), ["stop"])
                                }, " Edit ", 8, _hoisted_18),
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-sm btn-danger text-white btn-action",
                                  onClick: _withModifiers(($event: any) => (handleDelete(article.article_id)), ["stop"])
                                }, " Delete ", 8, _hoisted_19)
                              ])
                            ]),
                            _createElementVNode("td", null, _toDisplayString(article.article_headline), 1),
                            _createElementVNode("td", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(article.article_categories, (category, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  key: `article-category-${index}`,
                                  class: "badge badge-primary"
                                }, _toDisplayString(category), 1))
                              }), 128))
                            ]),
                            _createElementVNode("td", _hoisted_20, [
                              (article.article_type_online=='Y')
                                ? (_openBlock(), _createElementBlock("svg", _hoisted_21, _cache[7] || (_cache[7] = [
                                    _createElementVNode("path", {
                                      fill: "#1b8b04",
                                      d: "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true),
                              (article.article_type_online=='N')
                                ? (_openBlock(), _createElementBlock("svg", _hoisted_22, _cache[8] || (_cache[8] = [
                                    _createElementVNode("path", {
                                      fill: "#e40101",
                                      d: "M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("td", _hoisted_23, [
                              (article.article_type_inperson=='Y')
                                ? (_openBlock(), _createElementBlock("svg", _hoisted_24, _cache[9] || (_cache[9] = [
                                    _createElementVNode("path", {
                                      fill: "#1b8b04",
                                      d: "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true),
                              (article.article_type_inperson=='N')
                                ? (_openBlock(), _createElementBlock("svg", _hoisted_25, _cache[10] || (_cache[10] = [
                                    _createElementVNode("path", {
                                      fill: "#e40101",
                                      d: "M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true)
                            ])
                          ]))
                        }), 128))
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})