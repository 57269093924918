import { defineStore } from "pinia";
import { ref, onMounted, computed, watch } from "vue";
const AUTH_TOKEN_KEY = "easyREGCRMToken";
export const useUserStore = defineStore("user", () => {

    let _authToken = ref<string>('');

    function SET_AUTH_TOKEN(authToken?: string): void {
        if (authToken) {
            _authToken.value = authToken;
            localStorage.setItem(AUTH_TOKEN_KEY, authToken);
        } else {
            _authToken.value = '';
            localStorage.removeItem(AUTH_TOKEN_KEY);
        }
    }
    function login() {

    }
    function logout() {
        _authToken.value = '';
        localStorage.removeItem(AUTH_TOKEN_KEY);
    }
    onMounted(() => {
    });
    return {
        _authToken,
        SET_AUTH_TOKEN,
        login,
        logout
    }
})