import ApiInstance from "./ApiBase";

export const GetAssignments = async (): Promise<any> => {
    const res = await ApiInstance.get(`/assignments`);
    return res?.data;
};

export const GetAssignment = async (id: any): Promise<any> => {
    const res = await ApiInstance.get(`/assignment/${id}`);
    return res?.data;
};

export const ManagAssignments = async (assignment_id: any, assignment_email: string, assignment_client: string, assignment_event: string, assignment_code: string, action: string): Promise<any> => {
    const data = new FormData();
    data.append("assignment_id", assignment_id);
    data.append("action", action);
    data.append("assignment_email", assignment_email);
    data.append("assignment_client", assignment_client);
    data.append("assignment_event", assignment_event);
    data.append("assignment_code", assignment_code);
    const res = await ApiInstance.post(`/manage-assignment`, data);
    return res?.data;
};
