<template>
  <!-- Page Header -->
  <div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-sm-6 col-12">
          <h2>Assignments</h2>
        </div>
        <div class="col-sm-6 col-12 d-flex justify-content-end">
          <!-- Button to open create rule modal -->
          <button
            type="button"
            class="btn btn-md btn-primary text-light"
            @click="handleCreate"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Main Content Area -->
  <div class="container-fluid">
    <div class="email-wrap email-main-wrapper">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 box-col-12">
          <div class="email-right-aside">
            <div class="row">
              <div class="col-12">
                <!-- Table to display rules -->
                <div class="table-responsive theme-scrollbar">
                  <table class="table table-dashed">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Email</th>
                        <th scope="col">Name</th>
                        <th scope="col">Client</th>
                        <th scope="col">Event</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Loop through rules data to populate table rows -->
                      <tr
                        v-for="(assignment, index) in allAssignments"
                        :key="`assignment-${index}`"
                      >
                        <td>
                          <div class="d-flex gap-2">
                            <!-- Edit Button -->
                            <button
                              type="button"
                              class="btn btn-sm btn-light text-dark btn-action"
                              @click.stop="handleEdit(assignment.assignment_id)"
                            >
                              Edit
                            </button>
                            <!-- Delete Button -->
                            <button
                              type="button"
                              class="btn btn-sm btn-danger text-white btn-action"
                              @click.stop="handleDelete(assignment.assignment_id)"
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                        <!-- Display rule explanation text -->
                        <td>{{ assignment.assignment_email }}</td>
                        <td>{{ assignment.assignment_firstname }} {{ assignment.assignment_lastname }}</td>
                        <td>{{ assignment.assignment_client_name }}</td>
                        <td>{{ assignment.assignment_event_name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* Custom styles for action buttons */
.btn-action {
  white-space: nowrap;
  border: 1px solid #a20000;

  &:hover,
  &:focus {
    border: 1px solid #a20000;
  }
}
</style>

<script lang="ts" setup>
import { defineAsyncComponent, onMounted, ref } from "vue";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import { GetAssignments, ManageAssignments } from "@/services/ApiAssignments";
import { openModal } from "@kolirt/vue-modal";
import { useRouter } from "vue-router";

// Define refs to hold rule data and current pagination state
const allAssignments = ref<any[]>([]); // Stores all loaded rules

// Import component for rule management modal
const ManageCategory = defineAsyncComponent(
  () => import("@/components/modal/ManageAssignment.vue")
);

// Router instance for navigation if needed
const router = useRouter();

/**
 * Opens modal for creating a new rule.
 */
function handleCreate() {
  openModal(ManageAssignment, { assignment: "", edit_type: "create" })
    .then(() => {
      // Reload rules after editing to reflect changes
      loadAssignments();
    })
    .catch(() => {
      console.log("Edit modal closed without saving.");
    });
}

/**
 * Opens modal for editing an existing rule.
 * @param rule_id - ID of the rule to edit
 */
function handleEdit(assignment_id: string) {
  openModal(ManageAssignments, { assignment_id, edit_type: "update" })
    .then(() => {
      // Reload rules after editing to reflect changes
      loadAssignments();
    })
    .catch(() => {
      console.log("Edit modal closed without saving.");
    });
}

/**
 * Handle deleting a rule.
 * @param rule_id - ID of the rule to delete
 */
async function handleDelete(assignment_id: string) {
  await ManageAssignment(assignment_id, "", "delete");
  loadAssignments();
}

/**
 * Refreshes the rules list by resetting pagination and clearing data.
 */
function loadAssignments() {
  (async () => {
    try {
      const { rows } = await GetAssignments();
      allAssignments.value = rows;
    } catch (error) {}
  })();
}
onMounted(() => {
  loadAssignments();
});
</script>
