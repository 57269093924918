import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "page-title" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-6 col-12" }
const _hoisted_5 = { class: "col-sm-6 col-12 d-flex justify-content-end gap-2" }

import { defineAsyncComponent, onMounted, onUnmounted, ref, watch } from "vue";
import { GetEmailByTemplate, SaveTemplateDesign } from "@/services/ApiCore";
import { useRoute, useRouter } from "vue-router";
import TopolPlugin from "@topol.io/editor";

export default /*@__PURE__*/_defineComponent({
  __name: 'EditTemplates',
  setup(__props) {

let currentJson = ref<string>("");
let currentHtml = ref<string>("");
let template_description = ref<string>("");
let router = useRouter();
const route = useRoute();
let sendingEmail = ref<boolean>(false);
watch(
  () => route.params.template_id,
  async (templateID) => {
    initEditor();
  }
);
function handleCancel() {
  router.push(`/administration/templates-easyreg/list`);
}
function handleSave() {
  sendingEmail.value = true;
  TopolPlugin.save();
  // unlayer.exportHtml(async (data: any) => {
  //   var json = data.design; // design json
  //   var html = data.html; // design html

  //   const foundIndex = json.body.rows.findIndex(
  //     (x: any) => x.id == "maincontent"
  //   );
  //   let mainHtmlText =
  //     json.body.rows[foundIndex].columns[0].contents[0].values.text;

  //   console.log("json", json);
  //   // Save the json, or html here
  //   await SaveTemplateDesign(
  //     JSON.stringify(json),
  //     mainHtmlText,
  //     html,
  //     route.params.template_id.toString()
  //   );
  //   router.push(`/administration/templates-easyreg/list`);
  // });
}

function loadEditor() {
  try {
    const TOPOL_OPTIONS = {
      defaultTemplateSettings: {
        emailWidth: 660,
      },
      id: "#editor-container",
      htmlMinified: true,
      authorize: {
        apiKey: process.env.TOPOL_API_KEY,
        userId: 1,
      },
      disableAlerts: true,
      light: true,
      premadeBlocks: [
        {
          name: "Online Attendee Site",
          blocks: [
            {
              img: "https://2023-easyreg-prod.s3.ca-central-1.amazonaws.com/attachments/online_attendee_site_premade_block.png", // Image url, for best experience use width > 330 px
              name: "Online Attendee Site Block", // Or name if not image available
              // MJML JSON
              definition: [
                {
                  tagName: "mj-section",
                  attributes: {
                    "full-width": "620px",
                    padding: "10px 0px 10px 0px",
                    "mj-class": "section",
                  },
                  type: null,
                  children: [
                    {
                      tagName: "mj-column",
                      attributes: {
                        width: "100%",
                        "vertical-align": "top",
                      },
                      children: [
                        {
                          tagName: "mj-text",
                          content:
                            '<p>You can access the Online Attendee Site using the following link:<br>###attendee.accesslink###</p>\n          <p style="font-weight: bold; margin-bottom: 10px; margin-top: 10px;">OR</p>\n          <ul>\n              <li>Go to https://online.ourconference.events</li>\n              <li>Enter ###attendee.email### under Email Address</li>\n              <li>Enter ###attendee.code### under Code</li>\n              <li>Click Login</li>\n          </ul>',
                          attributes: {
                            padding: "10px 10px",
                            align: "left",
                            "line-height": 1.3,
                            containerWidth: 620,
                          },
                          uid: "H1lqIiX4lm",
                        },
                      ],
                      uid: "SJ3I0XVx7",
                    },
                  ],
                  layout: 1,
                  backgroundColor: "",
                  backgroundImage: "",
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                  uid: "rkqIjQNe7",
                },
              ],
            },
          ],
        },
      ],
      customBlocks: [],
      callbacks: {
        async onSave(json: any, html: any) {
          currentJson.value = json;
          currentHtml.value = html;
          if (sendingEmail.value) {
            await SaveTemplateDesign(
              JSON.stringify(json),
              "",
              html,
              route.params.template_id.toString()
            );
            sendingEmail.value = false;
            router.push(`/administration/templates-easyreg/list`);
          }
        },
        onLoaded(json: any, html: any) {
          TopolPlugin.save();
        },
      },
    };
    TopolPlugin.init(TOPOL_OPTIONS);
  } catch (err) {
    console.log("load editor", err);
  }
}
function loadDesign(json: any) {
  if (json) {
    TopolPlugin.load(json);
  }
}
function initEditor() {
  (async () => {
    try {
      loadEditor();
      const { json, html, subject, description } = await GetEmailByTemplate(
        route.params.template_id.toString()
      );
      template_description.value = description;
      currentJson.value = json;
      currentHtml.value = html;
      loadDesign(json);
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}
onUnmounted(() => {
  TopolPlugin.destroy();
});
onMounted(() => {
  (async () => {
    sendingEmail.value = false;
    console.log("route.params.template_id", route.params.template_id);
    initEditor();
  })();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h2", null, "Edit Template Design [" + _toDisplayString(_unref(template_description)) + "]", 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-md btn-primary text-light",
              onClick: _withModifiers(handleCancel, ["stop"])
            }, " Cancel "),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-md btn-primary text-light",
              onClick: _withModifiers(handleSave, ["stop"])
            }, " Save ")
          ])
        ])
      ])
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "container-fluid" }, [
      _createElementVNode("div", { class: "row" }, [
        _createElementVNode("div", { class: "editor-wrapper" }, [
          _createElementVNode("div", {
            id: "editor-container",
            class: "w-full"
          })
        ])
      ])
    ], -1))
  ], 64))
}
}

})